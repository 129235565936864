import React from 'react';
import '../styles/projectIntroduction.css';

const ProjectIntroduction = () => {
    return (
        <div className="project-introduction">
            <div className="intro-section">
                <div className="intro-text">
                    <h1>유아언어발달검사 및 치료지원 사업</h1>
                    <h3>본 사업은 만 3~5세 대상 언어발달 지연 아동을 조기 선별하고 개입하여 아동의 언어발달 지원 강화를 목표로 합니다.</h3>
                    <div className="project-details">
                        <li><strong>사업명:</strong> 강원특별자치도교육청 2024년 유아 언어발달 검사 및 치료지원 사업</li>
                        <li><strong>핵심 목적:</strong> 공교육의 공정한 출발선 보장을 위한 학령전기 적극적 언어발달 지원</li>
                        <li className="indented-content"><strong>사업 내용:</strong> 강원특별자치도교육청은 한림대학교 산학협력단과 협력하여 도내 유치원 및 어린이집의 만 3~5세 유아를 대상으로 '유아 언어발달 검사 및 치료지원' 사업을 진행합니다. 이 사업은 언어발달지연 유아를 조기에 발견하고 적극적으로 개입하여 유아기의 언어발달을 지원하는 것을 목표로 합니다. 또한, '더 나은 강원교육'을 위해 유아 언어발달 지원을 강화 및 유보통합선도교육청 추진 사업의 일환으로 진행됩니다.</li>
                        <li><strong>사업기간:</strong> 2024년 6월 ~ 11월</li>
                    </div>
                </div>
                <div className="intro-image">
                    <img src="/images/business_introduction.png" alt="유아 언어발달 지원" />
                </div>
            </div>
            <div className="procedure-section">
                <h2>사업 추진 절차</h2>
                <div className="procedure-steps">
                    <div className="step">
                        <h3>1. 참여기관 모집</h3>
                        <p>유치원 및 어린이집 대상 본 사업 참여 희망기관 신청</p>
                    </div>
                    <div className="step">
                        <h3>2. 언어발달 선별검사</h3>
                        <p>온라인 플랫폼을 통해 보호자 또는 교사가 유아의 언어발달 선별검사를 실시합니다.</p>
                    </div>
                    <div className="step">
                        <h3>3. 언어발달 심화검사</h3>
                        <p>선별검사 결과, 심화검사가 필요한 유아를 대상으로 언어발달 전문가가 1:1 심화검사를 실시합니다.</p>
                    </div>
                    <div className="step">
                        <h3>4. 상담 및 컨설팅</h3>
                        <p>심화검사 결과를 바탕으로 보호자 또는 교사를 대상으로 언어발달 상담 및 컨설팅을 제공합니다.</p>
                    </div>
                    <div className="step">
                        <h3>5. 언어치료 지원</h3>
                        <p>개별 맞춤형 언어치료 계획을 수립하고, 8~12회기의 언어치료 서비스를 제공합니다.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectIntroduction;