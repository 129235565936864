const surveyData = {
    group36_41: {
        title: '한국 영유아 발달선별검사 (30~41개월용)',
        description: '이 발달선별검사 도구는 영유아 건강검진 사업의 일환으로 보건복지부와 질병관리본부의 후원하에 대한소아과학회와 대한소아신경학회, 대한소아청소년정신의학회, 대한소아재활·발달의학회, 심리학 등 관련 분야의 전문가들이 모여 한국 영유아의 특성에 맞게 개발한 것입니다.',
        categories: [
            {
                name: '대근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '아무구조도 붙잡지 않고 한 계단에 한발씩 번갈아 내디디며 계단을 올라간다.',
                        image: '/images/question3641_01_01.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '큰 공을 던져주면 양팔과 가슴을 이용해 받는다.',
                        image: '/images/question3641_01_02.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '세발자전거의 페달을 밟아서 앞으로 나갈 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '선을 따라 똑바로 앞으로 걷는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '제자리에서 양발을 모아 멀리뛰기를 한다.',
                        image: '/images/question3641_01_05.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '아무것도 붙잡지 않고 한발로 3초 이상 서 있는다.',
                        image: '/images/question3641_01_06.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '보조 바퀴가 있는 두발자전거를 탈 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '한발로 두세 발자국 뛴다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '소근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '자신의 옷이나 인형 옷의 단추를 푼다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '원이 그려진 것을 보여주면 원을 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '종이를 두 번 연달아 접는다(접은 선은 정확하지 않아도 된다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '그려진 점선을 따라 선을 그린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '사각형이 그려진 것을 보여주면 사각형을 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다. 또한 각이 교차되도록 그리는 것은 괜찮지만, 둥글거나 좁은 각으로 그리는 것은 해당되지 않는다).',
                        image: '/images/question3641_02_05.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '가위로 직선을 따라 똑바로 오린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '뚜껑을 돌려서 연다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '십자(+)가 그려진 것을 보여주면 십자(+)를 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '/images/question3641_02_08.png',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '인지',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '연관성이 없는 두 가지 지시사항을 시키면 두 가지를 순서대로 기억하여 수행한다. (예: "휴지 버리고 책 가지고 오세요.")',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자신의 성별을 안다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'셋\'이라는 개념을 이해한다. (예: 사탕 여러 개를 책상 위에 놓고 "세 개 주세요."라고 하면 세 개를 준다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '과일, 탈 것, 가구가 그려진 그림카드를 섞어 놓았을 때, 아이가 같은 종류끼리 분류한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'가장 많은\', \'가장 적은\'의 개념을 모두 이해한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 사람이 한 말을 전달한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다섯 가지 이상의 색깔을 정확하게 구분한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '사람(예: 엄마, 아빠)을 그리라고 하면 신체의 세 부분 이상을 그린다.',
                        image: '/images/question3641_03_08.png',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '언어',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '"이름이 뭐예요?"하고 물으면, 성과 이름을 모두 말한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 의미를 가진 네 단어 이상을 연결하여 문장으로 말한다. (예: "장난감 사러 가게에 가요.")',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'~했어요.\'와 같이 과거형으로 말한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 대화를 주고받는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '완전한 문장으로 이야기한다. (예: "멍멍이가 까까를 먹었어.")',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'-은\', \'-는\', \'-이\', \'-가\'와 같은 조사를 적절히 사용하여 문장을 완성한다. (예: "고양이는 \'야옹\'하고 울어요.", "친구가 좋아요.")',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '같은 분류에 속한 것을 적어도 세 가지 이상 말한다. (예: 동물을 말하도록 시키면, \'강아지\', \'고양이\', \'코끼리\'와 같이 말한다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'~할 거에요.\', \'~하고 싶어요.\'와 같이 미래에 일어날 일을 상황에 맞게 표현한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '사회성',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '어른이 이끄는 집단 놀이에서 규칙을 따른다. (예: 기차놀이, 꼬리잡기, 사다리 터널 놀이 등)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 차례를 기다린다. (예: 놀이터, 미끄럼틀)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '놀이 중에 도움이 필요한 친구를 도와주고 달래준다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '또래와 함께 이야기의 흐름이 있는 놀이를 한다. (예: \'인형놀이\', \'학교놀이\' 등)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 사람에게 간단한 놀이의 규칙을 설명한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 아이들과 있을 때, 차례를 지키고 놀잇감을 나누어 논다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 아이들의 행동에 대해 이야기한다. (예:"OO가 과자를 가져왔어.")',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'소꿉놀이\', \'학교 놀이\', \'술래잡기\' 등 여럿이 함께 어울려 하는 다양한 놀이를 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '자조',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '양말을 혼자서 신는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '도와주지 않아도 혼자서 밥을 먹는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '단추를 풀어 주면 셔츠나 내의를 벗는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '장화(부츠)를 혼자서 신는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '옷의 앞과 뒤를 구분하여 입는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '혼자서 티셔츠를 입는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '외투의 큰 단추를 끼운다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '혼자서 손을 깨끗이 씻고 수건으로 닦는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '추가 질문',
                scoringCriteria: [
                    '예 (1)',
                    '아니오 (0)'
                ],
                questions: [
                    {
                        question: '의미 있는 단어를 말하지 못한다. (예: "멍멍", "맘마", "물" 등)',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '두 단어가 결합된 말을 하지 못한다. (예: "밥 줘.", "우유 먹자." 등).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '아이가 보호자와 눈을 잘 맞추지 않는다 (다른 것에 집중하고 있어서 눈을 맞추지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '이름을 불러도 대부분 쳐다보지 않는다 (청력에 이상이 있거나, 다른 것에 집중하고 있어서 불러도 쳐다보지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '어른들의 관심을 끄는 행동(예: 손가락으로 물건을 가리키면서 보호자의 반응을 살피기, 물건 가져다 보여주기, 같이 놀자고 조르기, 소리 내어 부르기 등)을 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '또래 아이들에게 관심이 없다. 또래와 함께 있어도 아이들을 지켜보거나, 행동을 따라 하거나, 함께 놀려고 시도하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '2인 이상이 하는 역할 놀이를 하지 않는다. (예: 의사와 환자 되어보기, 엄마와 아빠 되어보기 등)',
                        image: '',
                        options: [1, 0]
                    }
                ]
            }
        ]
    },
    group42_47: {
        title: '한국 영유아 발달선별검사 (42~47개월용)',
        description: '이 발달선별검사 도구는 영유아 건강검진 사업의 일환으로 보건복지부와 질병관리본부의 후원하에 대한소아과학회와 대한소아신경학회, 대한소아청소년정신의학회, 대한소아재활·발달의학회, 심리학 등 관련 분야의 전문가들이 모여 한국 영유아의 특성에 맞게 개발한 것입니다.',
        categories: [
            {
                name: '대근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '큰 공을 던져주면 양팔과 가슴을 이용해 받는다.',
                        image: '/images/question4247_01_01.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '세발자전거의 페달을 밟아서 앞으로 나갈 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '제자리에서 양발을 모아 멀리뛰기를 한다.',
                        image: '/images/question4247_01_03.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '아무것도 붙잡지 않고 한발로 3초 이상 서 있는다.',
                        image: '/images/question4247_01_04.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '보조 바퀴가 있는 두발자전거를 탈 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '한발로 두세 발자국 뛴다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '서 있는 자세에서 팔을 들어 머리 위로 공을 2미터 이상 앞으로 멀리 던진다.',
                        image: '/images/question4247_01_07.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '아무것도 붙잡지 않고 한 계단에 한발씩 번갈아 내디디며 계단을 내려간다.',
                        image: '/images/question4247_01_08.png',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '소근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '자신의 옷이나 인형 옷의 단추를 푼다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '원이 그려진 것을 보여주면 원을 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '종이를 두 번 연달아 접는다(접은 선은 정확하지 않아도 된다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '그려진 점선을 따라 선을 그린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '사각형이 그려진 것을 보여주면 사각형을 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다. 또한 각이 교차되도록 그리는 것은 괜찮지만, 둥글거나 좁은 각으로 그리는 것은 해당되지 않는다).',
                        image: '/images/question4247_02_05.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '가위로 직선을 따라 똑바로 오린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '십자(+)가 그려진 것을 보여주면 십자(+)를 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '/images/question4247_02_07.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '블록으로 계단 모양을 쌓는다.',
                        image: '/images/question4247_02_08.png',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '인지',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '과일, 탈 것, 가구가 그려진 그림카드를 섞어 놓았을 때, 아이가 같은 종류끼리 분류한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'가장 많은\', \'가장 적은\'의 개념을 모두 이해한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 사람이 한 말을 전달한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다섯 가지 이상의 색깔을 정확하게 구분한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '물건을 하나씩 열(10)까지 센다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '아침, 점심, 저녁, 오늘, 내일 등 시간의 개념을 이해한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '사람(예: 엄마, 아빠)을 그리라고 하면 신체의 세 부분 이상을 그린다.',
                        image: '/images/question4247_03_07.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '손에 닿지 않는 물건을 도구를 사용하여 가져온다. (예: 책상 밑에 들어간 것을 막대기로 꺼낸다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '언어',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '완전한 문장으로 이야기한다. (예: "멍멍이가 까까를 먹었어.")',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'-은\', \'-는\', \'-이\', \'-가\'와 같은 조사를 적절히 사용하여 문장을 완성한다. (예: "고양이는 \'야옹\'하고 울어요.", "친구가 좋아요.")',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '같은 분류에 속한 것을 적어도 세 가지 이상 말한다. (예: 동물을 말하도록 시키면, \'강아지\', \'고양이\', \'코끼리\'와 같이 말한다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'~할 거에요.\', \'~하고 싶어요.\'와 같이 미래에 일어날 일을 상황에 맞게 표현한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '그날 있었던 일을 이야기한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '친숙한 단어의 반대말을 말한다. (예: 덥다 ↔ 춥다, 크다 ↔ 작다)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 농담이나 빗대어 하는 말의 뜻을 알아차린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '단어의 뜻을 물어보면 설명한다. (예: "신발이 뭐야?"라는 질문에 "밖에 나갈 때 신는거요."와 같은 대답을 할 수 있다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '사회성',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '다른 사람에게 간단한 놀이의 규칙을 설명한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 아이들과 있을 때, 차례를 지키고 놀잇감을 나누어 논다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 아이들의 행동에 대해 이야기한다. (예:"OO가 과자를 가져왔어.")',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'소꿉놀이\', \'학교 놀이\', \'술래잡기\' 등 여럿이 함께 어울려 하는 다양한 놀이를 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기보다 어린 아동을 돌봐 주는 행동을 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'가위바위보\'로 승부를 정한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '처음 만난 또래와 쉽게 어울린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '또래와 함께 차례나 규칙을 알아야 할 수 있는 놀이를 한다. (예: 윷놀이, 보드게임)',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '자조',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '장화(부츠)를 혼자서 신는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '깨끗하게 닦지 못하더라도 혼자 칫솔에 치약을 묻혀 이를 닦고 헹군다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '옷의 앞과 뒤를 구분하여 입는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '혼자서 티셔츠를 입는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '외투의 큰 단추를 끼운다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '벙어리장갑의 좌우를 구분하여 손에 낀다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '혼자서 손을 깨끗이 씻고 수건으로 닦는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '식사 할 때 젓가락을 사용한다(연습용 젓가락도 포함).',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '추가 질문',
                scoringCriteria: [
                    '예 (1)',
                    '아니오 (0)'
                ],
                questions: [
                    {
                        question: '의미 있는 단어를 말하지 못한다. (예: "멍멍", "맘마", "물" 등)',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '두 단어가 결합된 말을 하지 못한다. (예: "밥 줘.", "우유 먹자." 등).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '아이가 보호자와 눈을 잘 맞추지 않는다 (다른 것에 집중하고 있어서 눈을 맞추지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '이름을 불러도 대부분 쳐다보지 않는다 (청력에 이상이 있거나, 다른 것에 집중하고 있어서 불러도 쳐다보지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '어른들의 관심을 끄는 행동(예: 손가락으로 물건을 가리키면서 보호자의 반응을 살피기, 물건 가져다 보여주기, 같이 놀자고 조르기, 소리 내어 부르기 등)을 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '또래 아이들에게 관심이 없다. 또래와 함께 있어도 아이들을 지켜보거나, 행동을 따라 하거나, 함께 놀려고 시도하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '2인 이상이 하는 역할 놀이를 하지 않는다. (예: 의사와 환자 되어보기, 엄마와 아빠 되어보기 등)',
                        image: '',
                        options: [1, 0]
                    }
                ]
            }
        ]
    },
    group48_53: {
        title: '한국 영유아 발달선별검사 (48~53개월용)',
        description: '이 발달선별검사 도구는 영유아 건강검진 사업의 일환으로 보건복지부와 질병관리본부의 후원하에 대한소아과학회와 대한소아신경학회, 대한소아청소년정신의학회, 대한소아재활·발달의학회, 심리학 등 관련 분야의 전문가들이 모여 한국 영유아의 특성에 맞게 개발한 것입니다.',
        categories: [
            {
                name: '대근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '아무것도 붙잡지 않고 한발로 3초 이상 서 있는다.',
                        image: '/images/question4853_01_01.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '보조 바퀴가 있는 두발자전거를 탈 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '한발로 두세 발자국 뛴다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '서 있는 자세에서 팔을 들어 머리 위로 공을 2미터 이상 앞으로 멀리 던진다.',
                        image: '/images/question4853_01_04.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '아무것도 붙잡지 않고 한 계단에 한발씩 번갈아 내디디며 계단을 내려간다.',
                        image: '/images/question4853_01_05.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '굴러가는 공을 발로 세운다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '2미터 거리에서 테니스공 크기의 공을 던지면 두 손으로 잡는다.',
                        image: '/images/question4853_01_07.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '공을 바닥에 한 번 튕길 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '소근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '사각형이 그려진 것을 보여주면 사각형을 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다. 또한 각이 교차되도록 그리는 것은 괜찮지만, 둥글거나 좁은 각으로 그리는 것은 해당되지 않는다).',
                        image: '/images/question4853_02_01.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '가위로 직선을 따라 똑바로 오린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '십자(+)가 그려진 것을 보여주면 십자(+)를 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '/images/question4853_02_03.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '블록으로 계단 모양을 쌓는다.',
                        image: '/images/question4853_02_04.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '색칠 공부의 그림 속에 색을 칠한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '종이에 그려진 네모를 가위로 오린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '블록으로 피라미드 모양을 쌓는다.',
                        image: '/images/question4853_02_07.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '엄지손가락과 다른 네 손가락을 차례로 맞닿게 한다(반대편 네 손가락이 아니고 같은 손이어야 한다).',
                        image: '/images/question4853_02_08.png',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '인지',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '과일, 탈 것, 가구가 그려진 그림카드를 섞어 놓았을 때, 아이가 같은 종류끼리 분류한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'가장 많은\', \'가장 적은\'의 개념을 모두 이해한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 사람이 한 말을 전달한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '물건을 하나씩 열(10)까지 센다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '아침, 점심, 저녁, 오늘, 내일 등 시간의 개념을 이해한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '사람(예: 엄마, 아빠)을 그리라고 하면 신체의 세 부분 이상을 그린다.',
                        image: '/images/question4853_03_06.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '손에 닿지 않는 물건을 도구를 사용하여 가져온다. (예: 책상 밑에 들어간 것을 막대기로 꺼낸다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자신이 원하는 TV 채널로 돌린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '언어',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '\'-은\', \'-는\', \'-이\', \'-가\'와 같은 조사를 적절히 사용하여 문장을 완성한다. (예: "고양이는 \'야옹\'하고 울어요.", "친구가 좋아요.")',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '같은 분류에 속한 것을 적어도 세 가지 이상 말한다. (예: 동물을 말하도록 시키면, \'강아지\', \'고양이\', \'코끼리\'와 같이 말한다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'~할 거에요.\', \'~하고 싶어요.\'와 같이 미래에 일어날 일을 상황에 맞게 표현한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '그날 있었던 일을 이야기한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '친숙한 단어의 반대말을 말한다. (예: 덥다 ↔ 춥다, 크다 ↔ 작다)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 농담이나 빗대어 하는 말의 뜻을 알아차린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '단어의 뜻을 물어보면 설명한다. (예: "신발이 뭐야?"라는 질문에 "밖에 나갈 때 신는거요."와 같은 대답을 할 수 있다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '가족 이외의 사람도 이해할 수 있을 정도로 모든 단어의 발음이 정확하다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '사회성',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '다른 사람에게 간단한 놀이의 규칙을 설명한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 아이들과 있을 때, 차례를 지키고 놀잇감을 나누어 논다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'소꿉놀이\', \'학교 놀이\', \'술래잡기\' 등 여럿이 함께 어울려 하는 다양한 놀이를 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기보다 어린 아동을 돌봐 주는 행동을 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'가위바위보\'로 승부를 정한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '처음 만난 또래와 쉽게 어울린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '또래와 함께 차례나 규칙을 알아야 할 수 있는 놀이를 한다. (예: 윷놀이, 보드게임)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 생각을 이야기하고 다른 아이의 말을 귀 기울여 듣는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '자조',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '벙어리장갑의 좌우를 구분하여 손에 낀다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '혼자서 손을 깨끗이 씻고 수건으로 닦는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '식사 할 때 젓가락을 사용한다(연습용 젓가락도 포함).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '혼자서 비누칠을 하여 손과 얼굴을 씻고 수건으로 닦는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '대소변을 볼 때 혼자서 옷을 벗고 입는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '밤에 자는 동안 대소변을 가린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '대소변을 본 후 화장실 물을 내린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '숟가락 등을 사용하여 빵에 버터나 잼을 바른다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '추가 질문',
                scoringCriteria: [
                    '예 (1)',
                    '아니오 (0)'
                ],
                questions: [
                    {
                        question: '아이가 보호자와 눈을 잘 맞추지 않는다 (다른 것에 집중하고 있어서 눈을 맞추지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '이름을 불러도 대부분 쳐다보지 않는다 (청력에 이상이 있거나, 다른 것에 집중하고 있어서 불러도 쳐다보지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '어른들의 관심을 끄는 행동(예: 손가락으로 물건을 가리키면서 보호자의 반응을 살피기, 물건 가져다 보여주기, 같이 놀자고 조르기, 소리 내어 부르기 등)을 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '또래 아이들에게 관심이 없다. 또래와 함께 있어도 아이들을 지켜보거나, 행동을 따라 하거나, 함께 놀려고 시도하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '간단한 규칙이 있으면서 편을 나누어서 하는 놀이(예: 숨바꼭질, 잡기 놀이 등)나 3인 이상이 모여 하는 규칙이 있는 역할놀이(예: 가게 놀이, 학교 놀이 등)를 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    }
                ]
            }
        ]
    },
    group54_59: {
        title: '한국 영유아 발달선별검사 (54~59개월용)',
        description: '이 발달선별검사 도구는 영유아 건강검진 사업의 일환으로 보건복지부와 질병관리본부의 후원하에 대한소아과학회와 대한소아신경학회, 대한소아청소년정신의학회, 대한소아재활·발달의학회, 심리학 등 관련 분야의 전문가들이 모여 한국 영유아의 특성에 맞게 개발한 것입니다.',
        categories: [
            {
                name: '대근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '한발로 두세 발자국 뛴다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '서 있는 자세에서 팔을 들어 머리 위로 공을 2미터 이상 앞으로 멀리 던진다.',
                        image: '/images/question5459_01_02.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '아무것도 붙잡지 않고 한 계단에 한발씩 번갈아 내디디며 계단을 내려간다.',
                        image: '/images/question5459_01_03.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '굴러가는 공을 발로 세운다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '2미터 거리에서 테니스공 크기의 공을 던지면 두 손으로 잡는다.',
                        image: '/images/question5459_01_05.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '공을 바닥에 한 번 튕길 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '무릎 아래 높이로 매어져 있는 줄을 뛰어 넘을 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '줄넘기를 1회 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '소근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '색칠 공부의 그림 속에 색을 칠한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '종이에 그려진 네모를 가위로 오린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '블록으로 피라미드 모양을 쌓는다.',
                        image: '/images/question5459_02_03.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '엄지손가락과 다른 네 손가락을 차례로 맞닿게 한다(반대편 네 손가락이 아니고 같은 손이어야 한다).',
                        image: '/images/question5459_02_04.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '삼각형이 그려진 것을 보여주면 삼각형을 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '아이의 이름을 적어주면 쓰여진 자기 이름을 보고 따라 쓴다(글자의 크기나 순서가 바뀌었거나 뒤집혔어도 된다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '종이에 그려진 동그라미를 가위로 오린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 자동차 모양을 흉내 내어 그린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '인지',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '아침, 점심, 저녁, 오늘, 내일 등 시간의 개념을 이해한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '손에 닿지 않는 물건을 도구를 사용하여 가져온다. (예: 책상 밑에 들어간 것을 막대기로 꺼낸다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자신이 원하는 TV 채널로 돌린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '동화책을 읽어주면 내용을 일부 이해하고 기억한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '엄마(보호자)가 많이 쓰는 물건들이 어떤 용도로 사용되는지 알고 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '1 더하기 2를 계산한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자신의 왼쪽과 오른쪽을 구분할 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '요일을 순서대로 알고 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '언어',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '그날 있었던 일을 이야기한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '친숙한 단어의 반대말을 말한다. (예: 덥다 ↔ 춥다, 크다 ↔ 작다)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 농담이나 빗대어 하는 말의 뜻을 알아차린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '단어의 뜻을 물어보면 설명한다. (예: "신발이 뭐야?"라는 질문에 "밖에 나갈 때 신는거요."와 같은 대답을 할 수 있다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'만약 ~라면 무슨 일이 일어날까?\'와 같이 가상의 상황에 대한 질문에 대답한다. (예: "동생이 있으면 어떨까?"',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '이름이나 쉬운 단어 2~3개를 보고 읽는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '가족 이외의 사람도 이해할 수 있을 정도로 모든 단어의 발음이 정확하다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 이름이나 2~4개의 글자로 된 단어를 보지 않고 쓸 수 있다. (예: 동생, 신호등, 대한민국)',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '사회성',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '자기보다 어린 아동을 돌봐 주는 행동을 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '처음 만난 또래와 쉽게 어울린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '또래와 함께 차례나 규칙을 알아야 할 수 있는 놀이를 한다. (예: 윷놀이, 보드게임)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 생각을 이야기하고 다른 아이의 말을 귀 기울여 듣는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '게임을 하는 방법에 대해 다른 아이와 이야기를 나눈다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 아이들과 적극적으로 어울리려고 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '시키지 않아도 아는 사람에게 "안녕하세요?"라고 인사한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '친구에게 자기 집으로 와서 같이 놀자고 하거나, 무슨 놀이를 하자고 제안한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '자조',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '혼자서 비누칠을 하여 손과 얼굴을 씻고 수건으로 닦는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '대소변을 볼 때 혼자서 옷을 벗고 입는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '밤에 자는 동안 대소변을 가린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '대소변을 본 후 화장실 물을 내린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '숟가락 등을 사용하여 빵에 버터나 잼을 바른다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '목욕한 후에 혼자서 몸을 수건으로 닦는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '윗옷의 지퍼를 혼자 끼워 올린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '옷이 더러워지면 스스로 알아서 갈아입는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '추가 질문',
                scoringCriteria: [
                    '예 (1)',
                    '아니오 (0)'
                ],
                questions: [
                    {
                        question: '아이가 보호자와 눈을 잘 맞추지 않는다 (다른 것에 집중하고 있어서 눈을 맞추지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '이름을 불러도 대부분 쳐다보지 않는다 (청력에 이상이 있거나, 다른 것에 집중하고 있어서 불러도 쳐다보지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '어른들의 관심을 끄는 행동(예: 손가락으로 물건을 가리키면서 보호자의 반응을 살피기, 물건 가져다 보여주기, 같이 놀자고 조르기, 소리 내어 부르기 등)을 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '또래 아이들에게 관심이 없다. 또래와 함께 있어도 아이들을 지켜보거나, 행동을 따라 하거나, 함께 놀려고 시도하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '간단한 규칙이 있으면서 편을 나누어서 하는 놀이(예: 숨바꼭질, 잡기 놀이 등)나 3인 이상이 모여 하는 규칙이 있는 역할놀이(예: 가게 놀이, 학교 놀이 등)를 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    }
                ]
            }
        ]
    },
    group60_65: {
        title: '한국 영유아 발달선별검사 (60~65개월용)',
        description: '이 발달선별검사 도구는 영유아 건강검진 사업의 일환으로 보건복지부와 질병관리본부의 후원하에 대한소아과학회와 대한소아신경학회, 대한소아청소년정신의학회, 대한소아재활·발달의학회, 심리학 등 관련 분야의 전문가들이 모여 한국 영유아의 특성에 맞게 개발한 것입니다.',
        categories: [
            {
                name: '대근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '굴러가는 공을 발로 세운다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '2미터 거리에서 테니스공 크기의 공을 던지면 두 손으로 잡는다.',
                        image: '/images/question6065_01_02.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '공을 바닥에 한 번 튕길 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '무릎 아래 높이로 매어져 있는 줄을 뛰어 넘을 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '깨금발로 좌우 한발씩 번갈아 뛴다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '줄넘기를 1회 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '두 손으로 한 발을 잡고, 닭싸움 자세로 세 번 이상 점프한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '굴러오는 공을 발로 찰 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '소근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '종이에 그려진 네모를 가위로 오린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '블록으로 피라미드 모양을 쌓는다.',
                        image: '/images/question6065_02_02.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '엄지손가락과 다른 네 손가락을 차례로 맞닿게 한다(반대편 네 손가락이 아니고 같은 손이어야 한다).',
                        image: '/images/question6065_02_03.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '삼각형이 그려진 것을 보여주면 삼각형을 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '종이에 그려진 동그라미를 가위로 오린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 자동차 모양을 흉내 내어 그린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '주전자나 물병의 물을 거의 흘리지 않고 컵에 붓는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '마름모가 그려진 것을 보여주면 마름모를 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '인지',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '자신이 원하는 TV 채널로 돌린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '동화책을 읽어주면 내용을 일부 이해하고 기억한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '엄마(보호자)가 많이 쓰는 물건들이 어떤 용도로 사용되는지 알고 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '1 더하기 2를 계산한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자신의 왼쪽과 오른쪽을 구분할 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '요일을 순서대로 알고 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '100원보다 500원짜리 동전이 더 가치가 있다는 것을 안다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 생일을 알고 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '언어',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '친숙한 단어의 반대말을 말한다. (예: 덥다 ↔ 춥다, 크다 ↔ 작다)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 농담이나 빗대어 하는 말의 뜻을 알아차린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '단어의 뜻을 물어보면 설명한다. (예: "신발이 뭐야?"라는 질문에 "밖에 나갈 때 신는거요."와 같은 대답을 할 수 있다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'만약 ~라면 무슨 일이 일어날까?\'와 같이 가상의 상황에 대한 질문에 대답한다. (예: "동생이 있으면 어떨까?"',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '이름이나 쉬운 단어 2~3개를 보고 읽는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '가족 이외의 사람도 이해할 수 있을 정도로 모든 단어의 발음이 정확하다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '끝말잇기를 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 이름이나 2~4개의 글자로 된 단어를 보지 않고 쓸 수 있다. (예: 동생, 신호등, 대한민국)',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '사회성',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '처음 만난 또래와 쉽게 어울린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '또래와 함께 차례나 규칙을 알아야 할 수 있는 놀이를 한다. (예: 윷놀이, 보드게임)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 생각을 이야기하고 다른 아이의 말을 귀 기울여 듣는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '게임을 하는 방법에 대해 다른 아이와 이야기를 나눈다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 아이들과 적극적으로 어울리려고 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '시키지 않아도 아는 사람에게 "안녕하세요?"라고 인사한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '친구에게 자기 집으로 와서 같이 놀자고 하거나, 무슨 놀이를 하자고 제안한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '친구나 가족에게 전화를 건다(집 전화나 휴대전화 모두 해당).',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '자조',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '밤에 자는 동안 대소변을 가린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '대소변을 본 후 화장실 물을 내린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '숟가락 등을 사용하여 빵에 버터나 잼을 바른다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '목욕한 후에 혼자서 몸을 수건으로 닦는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '윗옷의 지퍼를 혼자 끼워 올린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '옷이 더러워지면 스스로 알아서 갈아입는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '우유 종이팩을 어른의 도움 없이도 혼자서 연다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '대변을 본 뒤 휴지를 사용하여 혼자서 뒤처리를 할 수 있다(비데 사용도 포함).',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '추가 질문',
                scoringCriteria: [
                    '예 (1)',
                    '아니오 (0)'
                ],
                questions: [
                    {
                        question: '아이가 보호자와 눈을 잘 맞추지 않는다 (다른 것에 집중하고 있어서 눈을 맞추지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '이름을 불러도 대부분 쳐다보지 않는다 (청력에 이상이 있거나, 다른 것에 집중하고 있어서 불러도 쳐다보지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '어른들의 관심을 끄는 행동(예: 손가락으로 물건을 가리키면서 보호자의 반응을 살피기, 물건 가져다 보여주기, 같이 놀자고 조르기, 소리 내어 부르기 등)을 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '또래 아이들에게 관심이 없다. 또래와 함께 있어도 아이들을 지켜보거나, 행동을 따라 하거나, 함께 놀려고 시도하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '간단한 규칙이 있으면서 편을 나누어서 하는 놀이(예: 숨바꼭질, 잡기 놀이 등)나 3인 이상이 모여 하는 규칙이 있는 역할놀이(예: 가게 놀이, 학교 놀이 등)를 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '다른 사람의 감정이나 의도를 파악하지 못한다.',
                        image: '',
                        options: [1, 0]
                    }
                ]
            }
        ]
    },
    group66_71: {
        title: '한국 영유아 발달선별검사 (66~78개월용)',
        description: '이 발달선별검사 도구는 영유아 건강검진 사업의 일환으로 보건복지부와 질병관리본부의 후원하에 대한소아과학회와 대한소아신경학회, 대한소아청소년정신의학회, 대한소아재활·발달의학회, 심리학 등 관련 분야의 전문가들이 모여 한국 영유아의 특성에 맞게 개발한 것입니다.',
        categories: [
            {
                name: '대근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '굴러가는 공을 발로 세운다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '2미터 거리에서 테니스공 크기의 공을 던지면 두 손으로 잡는다.',
                        image: '/images/question6671_01_02.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '공을 바닥에 한 번 튕길 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '무릎 아래 높이로 매어져 있는 줄을 뛰어 넘을 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '깨금발로 좌우 한발씩 번갈아 뛴다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '줄넘기를 1회 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '두 손으로 한 발을 잡고, 닭싸움 자세로 세 번 이상 점프한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '굴러오는 공을 발로 찰 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '소근육운동',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '엄지손가락과 다른 네 손가락을 차례로 맞닿게 한다(반대편 네 손가락이 아니고 같은 손이어야 한다.)',
                        image: '/images/question6671_02_01.png',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '삼각형이 그려진 것을 보여주면 삼각형을 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '종이에 그려진 동그라미를 가위로 오린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 자동차 모양을 흉내 내어 그린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '주전자에 물병의 물을 거의 흘리지 않고 컵에 붓는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '마름모가 그려진 것을 보여주면 마름모를 그린다(그리는 과정의 시범을 보지 않고도 그려야 한다).',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '집, 나무, 동물 같은 사물을 알아 볼 수 있게 그린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '리본 묶기를 한다. (예: 운동화 끈)',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '인지',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '자신의 왼쪽과 오른쪽을 구분할 수 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '요일을 순서대로 알고 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '100원보다 500원짜리 동전이 더 가치가 있다는 것을 안다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 생일을 알고 있다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '11부터 20까지 숫자 중에서 하나를 불러주면 받아쓴다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '한 자리 숫자 뺄셈을 한다. (예: 5-2=3)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '엄마, 아빠, 혹은 보호자의 전화번호를 기억한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '달력에서 오늘 날짜(월, 일)을 바르게 가리킨다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '언어',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '친숙한 단어의 반대말을 말한다. (예: 덥다 ↔ 춥다, 크다 ↔ 작다)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 농담이나 빗대어 하는 말의 뜻을 알아차린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '단어의 뜻을 물어보면 설명한다. (예: "신발이 뭐야?"라는 질문에 "밖에 나갈 때 신는거요."와 같은 대답을 할 수 있다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '\'만약 ~라면 무슨 일이 일어날까?\'와 같이 가상의 상황에 대한 질문에 대답한다. (예: "동생이 있으면 어떨까?"',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '끝말잇기를 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 이름이나 2~4개의 글자로 된 단어를 보지 않고 쓸 수 있다. (예: 동생, 신호동, 대한민국)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 농담을 말한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '간단한 속담을 이해하고 사용한다. (예: \'누워서 떡먹기\'와 같은 속담을 적절하게 사용한다.)',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '사회성',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '처음 만난 또래와 쉽게 어울린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '또래와 함께 차례나 규칙을 알아야 할 수 있는 놀이를 한다. (예: 윷놀이, 보드게임)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '자기 생각을 이야기하고 다른 아이의 말을 귀 기울여 듣는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '게임을 하는 방법에 대해 다른 아이와 이야기를 나눈다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '다른 아이들과 적극적으로 어울리려고 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '시키지 않아도 아는 사람에게 "안녕하세요?"라고 인사한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '친구에게 자기 집으로 와서 같이 놀자고 하거나, 무슨 놀이를 하자고 제안한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '친구나 가족에게 전화를 건다(집 전화나 휴대전화 모두 해당).',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '자조',
                scoringCriteria: [
                    '잘 할 수 있다 (3)',
                    '할 수 있는 편이다 (2)',
                    '하지 못하는 편이다 (1)',
                    '전혀 할 수 없다 (0)'
                ],
                questions: [
                    {
                        question: '숟가락 등을 사용하여 빵에 버터나 잼을 바른다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '목욕한 후에 혼자서 몸을 수건으로 닦는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '윗옷의 지퍼를 혼자 끼워 올린다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '옷이 더러워지면 스스로 알아서 갈아입는다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '우유 종이팩을 어른의 도움 없이도 혼자서 연다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '일정 기간(일주일 정도) 동안 집안일 하나를 맡아서 한다. (예: 수저 놓기, 장난감 정리 등)',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '머리 감기를 제외하면 혼자서 목욕을 한다.',
                        image: '',
                        options: [3, 2, 1, 0]
                    },
                    {
                        question: '대변을 본 뒤 휴지를 사용하여 혼자서 뒤처리를 할 수 있다(비데 사용도 포함)',
                        image: '',
                        options: [3, 2, 1, 0]
                    }
                ]
            },
            {
                name: '추가 질문',
                scoringCriteria: [
                    '예 (1)',
                    '아니오 (0)'
                ],
                questions: [
                    {
                        question: '아이가 보호자와 눈을 잘 맞추지 않는다 (다른 것에 집중하고 있어서 눈을 맞추지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '이름을 불러도 대부분 쳐다보지 않는다 (청력에 이상이 있거나, 다른 것에 집중하고 있어서 불러도 쳐다보지 않는 경우는 해당되지 않는다).',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '어른들의 관심을 끄는 행동(예: 손가락으로 물건을 가리키면서 보호자의 반응을 살피기, 물건 가져다 보여주기, 같이 놀자고 조르기, 소리 내어 부르기 등)을 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '또래 아이들에게 관심이 없다. 또래와 함께 있어도 아이들을 지켜보거나, 행동을 따라 하거나, 함께 놀려고 시도하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '간단한 규칙이 있으면서 편을 나누어서 하는 놀이(예: 숨바꼭질, 잡기 놀이 등)나 3인 이상이 모여 하는 규칙이 있는 역할놀이(예: 가게 놀이, 학교 놀이 등)를 하지 않는다.',
                        image: '',
                        options: [1, 0]
                    },
                    {
                        question: '다른 사람의 감정이나 의도를 파악하지 못한다.',
                        image: '',
                        options: [1, 0]
                    }
                ]
            }
        ]
    }
};

export default surveyData;
