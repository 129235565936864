import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ProjectPage from './pages/ProjectPage';
import FAQPage from './pages/FAQPage';
import TestPage from './pages/TestPage';
import { SurveyProvider } from './context/SurveyContext';

const App = () => {
    return (
        <SurveyProvider>
            <div>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/project" element={<ProjectPage />} />
                    <Route path="/faq" element={<FAQPage />} />
                    <Route path="/test" element={<TestPage />} />
                </Routes>
                <Footer />
            </div>
        </SurveyProvider>
    );
};

export default App;
