import React from 'react';
import '../styles/progressBar.css';

const ProgressBar = ({ currentStep, totalSteps }) => {
    const percentage = (currentStep / totalSteps) * 100;

    return (
        <div className="progress-bar">
            <div className="progress-bar-inner" style={{ width: `${percentage}%` }}></div>
            <p>{currentStep} / {totalSteps} 진행 중</p>
        </div>
    );
};

export default ProgressBar;
