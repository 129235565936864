import React from 'react';
import ProjectIntroduction from '../components/ProjectIntroduction';
import '../styles/projectPage.css';

const ProjectPage = () => {
    return (
        <div className="ProjectPage">
            <main>
                <ProjectIntroduction />
            </main>
        </div>
    );
};

export default ProjectPage;
