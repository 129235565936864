import React from 'react';
import '../styles/footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-logos">
                    <img src="/images/kangwon-logo.png" alt="강원도교육청" className="footer-logo" />
                    <img src="/images/hallym-logo.png" alt="한림대학교" className="footer-logo" />
                </div>
                <div className="footer-info">
                    <div className="footer-info-text">
                        <p><b>[운영사무국]</b> 두루바른사회적협동조합</p>
                        <p><b>A </b>강원특별자치도 원주시 시청로 21-1, 301호 | <b>E-mail </b>gwchild.durubarun@gmail.com</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;