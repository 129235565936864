import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/stepOverview.css";

const StepOverview = () => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate("/test?step=2");
  };

  return (
    <div className="step-overview">
      <h1>유아 언어발달 선별검사</h1>
      <div className="headline-title">
        <h3>
          선별검사는 보호자 또는 교사 보고를 통한 약식 검사로
          <br />
          유아의 언어발달이 또래에 맞게 잘 발달하고 있는지를 확인하기 위한
          목적으로 실시합니다.
          <br />
          <span className="highlight">
            본 검사를 통해 더 자세한 심화검사가 필요한 유아를 선별합니다.
          </span>
        </h3>
      </div>
      <div className="content-wrapper">
        <div className="flow-chart">
          <div className="flow-item active">
            <span>언어발달 선별검사</span>
            <div className="sub-text">[심화검사 대상 선별]</div>
          </div>
          <div className="arrow"></div>
          <div className="flow-item">언어발달 심화검사</div>
          <div className="arrow"></div>
          <div className="flow-item">컨설팅 (보호자, 교사)</div>
          <div className="arrow"></div>
          <div className="flow-item">언어발달 치료지원</div>
        </div>
        <div className="info-box">
          <ul>
            <li>
              <strong>실시검사:</strong> 본사업에서는 영유아 건강검진에서
              활용하고 있는 "한국 영유아 발달선별검사(K-DST) 검사"와, 추가로
              3,4세는 "유아언어발달체크리스트", 5세는 "언어기반 문해 검사"를
              실시합니다.
            </li>
            <li>
              <strong>검사실시:</strong> 선별검사에 동의하시면 검사 실시
              페이지로 진행됩니다. 검사는 각 단계별로 안내되는 절차에 따라 유아
              행동발달을 잘 알고 있는 보호자 또는 교사가 검사에 응해주시면
              됩니다.
            </li>
            <li>
              <strong>소요시간:</strong> 총 검사는 약 15분 내외 소요됩니다.
            </li>
            <li>
              <strong>
                검사 실시 관련 질문이 있으신 경우 온라인 플랫폼 '자주 묻는
                질문'을 확인하시거나 운영사무국(gwchild.durubarun@gmail.com)으로
                연락주시기 바랍니다.
              </strong>
            </li>
          </ul>
        </div>
      </div>
      <button className="confirm-button" onClick={handleConfirm}>
        확인
      </button>
    </div>
  );
};

export default StepOverview;
