import React from "react";
import "../styles/stepIntroduction.css";

const StepIntroduction = ({ onNext, onPrevious }) => {
  return (
    <div className="introduction-container">
      <div className="introduction-content">
        <div className="introduction-left">
          <h2>한국형 영유아 발달선별검사(K-DST)</h2>
          <p>
            본 검사는 유아가 자기 연령에 맞게 잘 발달하고 있는지 또는 자세한
            심화언어검사가 필요한지를 확인하기 위한 목적으로 실시합니다.
          </p>
          <h3>&lt;작성방법&gt;</h3>
          <p>
            각 문항을 잘 읽고 다음의 네 가지 답지 중 하나에 체크해 주십시오.
          </p>
          <ul>
            <li>
              <span className="li-decoration">전혀 할 수 없다:</span> 전혀 하지
              못함
            </li>
            <li>
              <span className="li-decoration">하지 못하는 편이다:</span> 간혹 할
              때도 있지만 대부분 못함
            </li>
            <li>
              <span className="li-decoration">할 수 있는 편이다:</span> 간혹
              못할 때도 있지만 대부분 잘 함
            </li>
            <li>
              <span className="li-decoration">잘 할 수 있다:</span> 항상 잘함
            </li>
          </ul>
        </div>
        <div className="introduction-right">
          <img src="/images/kdst-description.png" alt="K-DST 설명" />
        </div>
      </div>
      <div className="navigation-buttons">
        <button className="btn-previous" onClick={onPrevious}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon-previous"
          >
            <path
              id="Vector"
              d="M3.41498 5.98773L7.36498 2.03773C7.54713 1.84913 7.64793 1.59652 7.64565 1.33433C7.64337 1.07213 7.5382 0.821319 7.3528 0.635911C7.16739 0.450503 6.91657 0.345334 6.65438 0.343055C6.39218 0.340777 6.13958 0.441571 5.95098 0.623729L0.293977 6.28073C0.200791 6.37338 0.126842 6.48354 0.0763807 6.60488C0.025919 6.72621 -5.81741e-05 6.85632 -5.81741e-05 6.98773C-5.81741e-05 7.11914 0.025919 7.24925 0.0763807 7.37058C0.126842 7.49192 0.200791 7.60208 0.293977 7.69473L5.95098 13.3517C6.04322 13.4472 6.15357 13.5234 6.27557 13.5758C6.39758 13.6282 6.5288 13.6558 6.66158 13.657C6.79435 13.6581 6.92603 13.6328 7.04893 13.5826C7.17183 13.5323 7.28348 13.458 7.37737 13.3641C7.47126 13.2702 7.54552 13.1586 7.5958 13.0357C7.64608 12.9128 7.67138 12.7811 7.67023 12.6483C7.66907 12.5155 7.64149 12.3843 7.58908 12.2623C7.53667 12.1403 7.46049 12.03 7.36498 11.9377L3.41498 7.98773H13.001C13.2662 7.98773 13.5205 7.88237 13.7081 7.69484C13.8956 7.5073 14.001 7.25295 14.001 6.98773C14.001 6.72251 13.8956 6.46816 13.7081 6.28062C13.5205 6.09309 13.2662 5.98773 13.001 5.98773H3.41498Z"
              fill="currentColor"
            />
          </svg>
          이전
        </button>
        <button className="btn-next" onClick={onNext}>
          다음
          <img src="/images/Vector_w.svg" alt="next" className="icon-next" />
        </button>
      </div>
    </div>
  );
};

export default StepIntroduction;
