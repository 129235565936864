import React, { createContext, useState } from 'react';

export const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
    const [surveyData, setSurveyData] = useState({
        basicInfo: {},
        results: []
    });

    const updateSurveyData = (step, data) => {
        setSurveyData(prevData => {
            const newData = { ...prevData, [step]: data };
            console.log(`Updated Survey Data [${step}]:`, newData); // 데이터 확인용 로그
            return newData;
        });
    };

    return (
        <SurveyContext.Provider value={{ surveyData, updateSurveyData }}>
            {children}
        </SurveyContext.Provider>
    );
};
