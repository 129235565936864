import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { SurveyContext } from "../context/SurveyContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import "chart.js/auto";
import "../styles/stepReport.css";
import { percentileData } from "../data/percentileData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const API_URL =
  process.env.REACT_APP_API_URL || "https://www.gwchild.co.kr/api";

// 연령 범위 매핑 함수
const getAgeRangeLabel = (ageInMonths) => {
  if (ageInMonths >= 30 && ageInMonths <= 41) return "30-41개월";
  if (ageInMonths >= 42 && ageInMonths <= 47) return "42-47개월";
  if (ageInMonths >= 48 && ageInMonths <= 53) return "48-53개월";
  if (ageInMonths >= 54 && ageInMonths <= 59) return "54-59개월";
  if (ageInMonths >= 60 && ageInMonths <= 65) return "60-65개월";
  if (ageInMonths >= 66 && ageInMonths <= 78) return "66-78개월";

  // 30개월 미만이거나 78개월 초과인 경우 가장 가까운 범위로 처리
  if (ageInMonths < 30) return "30-41개월";
  if (ageInMonths > 78) return "66-78개월";

  return null; // 이 부분은 실행되지 않아야 합니다.
};

// 백분위 점수 변환 함수
const convertRawScoreToPercentile = (ageRange, category, rawScore) => {
  const categoryData =
    percentileData[ageRange] && percentileData[ageRange][category];

  if (!categoryData) {
    console.log(`No data found for the age range: ${ageRange}`);
    return 1; // 기본값 1%로 설정
  }

  const percentileEntry = categoryData.find(
    (entry) => entry.rawScore === rawScore
  );
  return percentileEntry ? percentileEntry.percentile : 1; // 기본값 1%로 설정
};

const StepReport = () => {
  const { surveyData } = useContext(SurveyContext);
  const [reportData, setReportData] = useState({
    surveyCategorySums: [],
    selectedSurveyOptions: [],
    checklistCategorySums: [],
    selectedChecklistOptions: [],
    ageInMonths: 0,
    ageRange: "",
    institutionId: null,
  });

  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const submitAttempted = useRef(false);

  const submitSurveyData = useCallback(
    async (data) => {
      if (submitAttempted.current) return;
      submitAttempted.current = true;

      try {
        const response = await axios.post(`${API_URL}/survey/submit`, {
          privacyConsent: surveyData.privacyConsent,
          institutionId: data.institutionId,
          basicInfo: surveyData.basicInfo,
          surveyResponses: {
            selectedSurveyOptions: data.selectedSurveyOptions,
            surveyCategorySums: data.surveyCategorySums,
          },
          checklistResponses: {
            selectedChecklistOptions: data.selectedChecklistOptions,
            checklistCategorySums: data.checklistCategorySums,
          },
        });
        console.log("데이터 제출 성공:", response.data);
        setIsDataSubmitted(true);
      } catch (error) {
        console.error("데이터 제출 오류:", error);
        submitAttempted.current = false;
      }
    },
    [surveyData]
  );

  useEffect(() => {
    if (!isDataSubmitted) {
      const storedData = {
        surveyCategorySums: JSON.parse(
          localStorage.getItem("surveyCategorySums") || "[]"
        ),
        selectedSurveyOptions: JSON.parse(
          localStorage.getItem("selectedSurveyOptions") || "[]"
        ),
        checklistCategorySums: JSON.parse(
          localStorage.getItem("checklistCategorySums") || "[]"
        ),
        selectedChecklistOptions: JSON.parse(
          localStorage.getItem("selectedChecklistOptions") || "[]"
        ),
        ageInMonths: parseInt(localStorage.getItem("ageInMonths") || "0"),
        institutionId: localStorage.getItem("institutionId"),
      };

      const ageRangeLabel = getAgeRangeLabel(storedData.ageInMonths);
      storedData.ageRange = ageRangeLabel;
      setReportData(storedData);
      submitSurveyData(storedData);
    }
  }, [isDataSubmitted, submitSurveyData]);

  const cutoffPoints = {
    "30-41개월": {
      대근육운동: [12, 16, 23],
      소근육운동: [10, 15, 23],
      인지: [10, 16, 23],
      언어: [7, 19, 24],
      사회성: [12, 17, 24],
      자조: [11, 15, 23],
    },
    "42-47개월": {
      대근육운동: [14, 18, 24],
      소근육운동: [13, 17, 24],
      인지: [13, 18, 24],
      언어: [12, 19, 24],
      사회성: [12, 17, 23],
      자조: [13, 18, 24],
    },
    "48-53개월": {
      대근육운동: [14, 18, 24],
      소근육운동: [13, 18, 24],
      인지: [14, 18, 24],
      언어: [13, 19, 24],
      사회성: [12, 16, 23],
      자조: [15, 20, 24],
    },
    "54-59개월": {
      대근육운동: [15, 18, 24],
      소근육운동: [15, 20, 24],
      인지: [12, 17, 24],
      언어: [12, 17, 24],
      사회성: [12, 16, 23],
      자조: [16, 20, 24],
    },
    "60-65개월": {
      대근육운동: [15, 19, 24],
      소근육운동: [16, 21, 24],
      인지: [12, 17, 24],
      언어: [12, 17, 24],
      사회성: [12, 16, 23],
      자조: [14, 18, 23],
    },
    "66-78개월": {
      대근육운동: [16, 20, 24],
      소근육운동: [16, 20, 24],
      인지: [10, 17, 24],
      언어: [12, 17, 24],
      사회성: [13, 17, 24],
      자조: [13, 18, 24],
    },
  };

  const labels = ["대근육운동", "소근육운동", "인지", "언어", "사회성", "자조"];

  const percentileScores = reportData.surveyCategorySums
    .slice(0, 6)
    .map((score, index) => {
      const percentile = convertRawScoreToPercentile(
        reportData.ageRange,
        labels[index],
        score
      );
      console.log(
        `Label: ${labels[index]}, Score: ${score}, Percentile: ${percentile}`
      );
      return percentile;
    });

  const surveyDataForChart = {
    labels: labels,
    datasets: [
      {
        label: "백분위 점수",
        data: percentileScores,
        backgroundColor: "#ec614c",
        borderColor: "#ec614c",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: { y: { beginAtZero: true, max: 100, ticks: { stepSize: 20 } } },
    plugins: { legend: { display: false } },
    responsive: true,
    maintainAspectRatio: false,
  };

  // 점수 해석 함수 수정
  const interpretScore = (category, score, ageInMonths) => {
    let ageRange = getAgeRangeLabel(ageInMonths);
    const points = cutoffPoints[ageRange] && cutoffPoints[ageRange][category];

    if (!points) return "N/A";

    if (score < points[0]) return "관심 수준";
    if (score < points[1]) return "추적관찰 요망";
    if (score < points[2]) return "또래 수준";
    return "빠른 수준";
  };

  const renderCategoryResults = () => {
    const relevantSums = reportData.surveyCategorySums.slice(0, 6);
    return (
      <div className="category-results">
        <table>
          <thead>
            <tr>
              {labels.map((label, index) => (
                <th key={index}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {relevantSums.map((score, index) => (
                <td key={index}>
                  {percentileScores[index] !== undefined
                    ? percentileScores[index] + "%"
                    : "N/A"}
                </td>
              ))}
            </tr>
            <tr>
              {relevantSums.map((score, index) => (
                <td key={index}>
                  {score !== undefined
                    ? interpretScore(labels[index], score, reportData.ageInMonths)
                    : "N/A"}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getSummary = () => {
    const relevantScores = reportData.surveyCategorySums.slice(0, 6);
    const interpretations = relevantScores.map((score, idx) =>
      interpretScore(labels[idx], score, reportData.ageInMonths)
    );
  
    const languageScore = relevantScores[3];
    const languageLevel = interpretScore("언어", languageScore, reportData.ageInMonths);
    const otherLevels = interpretations.filter((_, idx) => idx !== 3);
  
    if (languageLevel === "관심 수준") {
      return `${surveyData.basicInfo.childName} 언어 발달이 유아 연령에 비해 느린 수준으로 나타났습니다. 보다 자세한 언어 발달을 확인하기 위한 심화 언어평가를 필히 권유드리며, 심화 검사 후 언어발달 전문가와의 컨설팅을 통하여 전문적인 지원을 받으시길 바랍니다. (※ ‘언어영역’이 관심수준에 해당하는 경우만 심화검사 대상으로 포함합니다. 다른 영역 결과는 유아 발달의 참조 자료로 제공합니다.)`;
    } else if (languageLevel === "추적관찰 요망") {
      return `${surveyData.basicInfo.childName} 언어 발달이 유아 연령에 비해 다소 느린 수준으로 나타났습니다. 가급적 보다 자세한 언어 발달을 확인하기 위한 심화 언어평가를 권유드립니다. (※ ‘언어영역’이 관심수준에 해당하는 경우만 심화검사 대상으로 포함합니다. 다른 영역 결과는 유아 발달의 참조 자료로 제공합니다.)`;
    } else if (otherLevels.includes("관심 수준")) {
      return `언어는 유아 연령에 적합한 발달을 보여 심화검사 대상에는 포함되지 않았으나, 언어 외의 영역 중 최소 한 영역에서의 발달이 유아 연령에 비해 다소 느린 수준으로 나타났습니다. 검사 결과를 참조하셔서 느린 영역의 발달을 도와주시고, 이후 발달에 큰 변화가 나타나지 않는 경우 관련 전문가와의 상담을 권유드립니다. (※ ‘언어영역’이 관심수준에 해당하는 경우만 심화검사 대상으로 포함합니다. 다른 영역 결과는 유아 발달의 참조 자료로 제공합니다.)`;
    } else if (otherLevels.includes("추적관찰 요망")) {
      return `언어는 유아 연령에 적합한 발달을 보여 심화검사 대상에는 포함되지 않았으나, 언어 외의 영역 중 최소 한 영역에서의 발달이 유아 연령에 비해 다소 느린 수준으로 나타났습니다. 검사 결과를 참조하셔서 느린 영역의 발달을 도와주시고, 이후 발달에 큰 변화가 나타나지 않는 경우 관련 전문가와의 상담을 권유드립니다. (※ ‘언어영역’이 관심수준에 해당하는 경우만 심화검사 대상으로 포함합니다. 다른 영역 결과는 유아 발달의 참조 자료로 제공합니다.)`;
    } else {
      return `유아는 연령에 적합한 발달을 하는 것으로 나타났습니다. 검사 결과를 참조하셔서 이후의 발달도 순조롭게 진행되도록 도와주시기 바랍니다. (※ ‘언어영역’이 관심수준에 해당하는 경우만 심화검사 대상으로 포함합니다. 다른 영역 결과는 유아 발달의 참조 자료로 제공합니다.)`;
    }
  };

  const handleSaveAsPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 5;
    const contentWidth = pageWidth - 2 * margin;

    // 임시로 PC 버전 CSS 클래스를 추가
    const reportWrapper = document.querySelector(".report-wrapper");
    reportWrapper.classList.add("pc-version");

    // 원래의 스타일을 저장
    const originalWidth = reportWrapper.style.width;

    // PDF 생성용 임시 스타일 적용
    reportWrapper.style.width = "800px";

    const addContentToPDF = (content, y) => {
      return new Promise((resolve) => {
        html2canvas(content, { width: 800, scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const aspectRatio = canvas.width / canvas.height;
          const imgWidth = contentWidth;
          const imgHeight = imgWidth / aspectRatio;

          pdf.addImage(imgData, "PNG", margin, y, imgWidth, imgHeight);
          resolve(y + imgHeight);
        });
      });
    };

    let currentY = margin;
    const contents = [
      document.querySelector(".report-container"),
      ...document.querySelectorAll(".report-section"),
    ];

    const processContents = (index) => {
      if (index >= contents.length) {
        // PDF 저장 후 PC 버전 CSS 클래스 제거 및 원래의 스타일 복원
        reportWrapper.classList.remove("pc-version");
        reportWrapper.style.width = originalWidth;
        pdf.save("report.pdf");
        return;
      }

      const content = contents[index];
      const scaleFactor = contentWidth / content.offsetWidth;
      const scaledHeight = content.offsetHeight * scaleFactor;

      if (currentY + scaledHeight > pageHeight - margin) {
        pdf.addPage();
        currentY = margin;
      }

      addContentToPDF(content, currentY).then((newY) => {
        currentY = newY + 5; // Add some space between sections
        processContents(index + 1);
      });
    };

    processContents(0);
  };

  const isMobile = window.innerWidth <= 768;

  const handleMobilePDFSave = () => {
    if (isMobile) {
      alert("모바일에서는 PC 버전의 PDF 출력물을 생성하여 전달합니다.");
      handleSaveAsPDF();
    }
  };

  const handleSaveAsPDFButton = isMobile
    ? handleMobilePDFSave
    : handleSaveAsPDF;

  if (!surveyData || !surveyData.basicInfo) {
    return <p>데이터를 불러오는 중입니다...</p>;
  }

  const { institutionName, gender, birthdate, childName, area, writerType } =
    surveyData.basicInfo;
  const examDate = new Date().toISOString().split("T")[0];
  const ageYears = Math.floor(reportData.ageInMonths / 12);
  const ageMonthsRemainder = reportData.ageInMonths % 12;

  return (
    <div className="report-wrapper">
      <div className="report-container">
        <p className="header-preface">
          강원특별자치도교육청
          <br />
          2024 유아 언어발달 검사 및 치료지원사업
        </p>
        <h2>선별검사 결과보고서</h2>
        <div className="report-info">
          <div>
            <span>아동명:</span>
            <span>{childName}</span>
          </div>
          <div>
            <span>성별:</span>
            <span>{gender}</span>
          </div>
          <div>
            <span>생년월일:</span>
            <span>{birthdate}</span>
          </div>
          <div>
            <span>연령:</span>
            <span>
              {ageYears}세 {ageMonthsRemainder}개월
            </span>
          </div>
          <div>
            <span>소속기관:</span>
            <span>{institutionName}</span>
          </div>
          <div>
            <span>권역:</span>
            <span>{area}</span>
          </div>
          <div>
            <span>작성자:</span>
            <span>{writerType}</span>
          </div>
          <div>
            <span>검사일:</span>
            <span>{examDate}</span>
          </div>
        </div>
      </div>
      <div id="report-content" className="report-content">
        <div className="report-section">
          <h2>검사 결과</h2>
          <p>
            <b>{childName} 유아</b>의 검사 결과는 다음과 같습니다.
          </p>
          <div className="chart-container">
            <Bar data={surveyDataForChart} options={chartOptions} />
          </div>
          {renderCategoryResults()}
        </div>
        <div className="report-section">
          <div className="report-data">
            <h3>검사 결과 해석</h3>
            <p>
              <b>빠른 수준:</b> 같은 연령 또래와 비교하여 발달이 빠른 수준
              <br />
              <b>또래 수준:</b> 같은 연령 또래와 비교하여 발달이 평균적인 수준
              <br />
              <b>추적관찰 요망:</b> 같은 연령 또래와 비교하여 발달이 다소 느린
              수준
              <br />
              <b>관심 수준:</b> 같은 연령 또래와 비교하여 발달이 느린 수준
            </p>
          </div>
          <div className="report-data">
            <h3>검사 영역 설명</h3>
            <p>
              <b>대근육:</b> 팔·다리와 고개, 몸통 등을 움직이는 행동, 즉 목
              가누기, 기기, 걷기, 달리기, 차기 등의 대근육 운동을 평가합니다.
              <br />
              <b>소근육:</b> 잡기, 블록 쌓기, 쓰기, 그리기, 자르기 등과 같이
              주로 손이나 손가락을 사용한 소근육 운동을 평가합니다.
              <br />
              <b>인지:</b> 환경 자극에 대한 시청각적 지각, 사고, 추리, 비교 및
              분류, 기억 및 모방, 수 개념, 공간 개념, 문제해결 등과 관련된
              행동을 평가합니다. 인지 능력은 놀이와 학습 영역의 상당 부분을
              포함합니다.
              <br />
              <b>언어:</b> 어휘나 문장을 이해하고 표현하는 능력, 사회적
              상황에서의 언어 사용, 발음 등을 포괄적으로 평가합니다.
              <br />
              <b>사회성:</b> 친구나 주변 사람들에게 관심 갖기, 사회적 관계
              형성하기, 사회적 규범 따르기 등과 같은 사회적 발달을 평가합니다.
              <br />
              <b>자조:</b> 식사하기, 대소변 가리기, 옷 입고 벗기, 청결과 위생
              등과 같이 환경에 적응하고 독립적인 일상생활을 하는 데 필요한
              기술들을 평가합니다.
            </p>
          </div>
        </div>
        <div className="report-section">
          <h2>검사 결과 요약</h2>
          <p className="summary">{getSummary()}</p>
          <div className="small-logo">
            <img
              src="/images/kangwon-logo.png"
              alt="강원도교육청"
              className="report-logo"
            />
            <img
              src="/images/hallym-logo.png"
              alt="한림대학교"
              className="report-logo"
            />
          </div>
        </div>
      </div>
      <div className="button-container">
        <button onClick={handleSaveAsPDFButton}>PDF로 저장</button>
      </div>
    </div>
  );
};

export default StepReport;
