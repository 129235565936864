import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import SurveyPage from "./SurveyPage";
import StepIntroduction from "./StepIntroduction";
import surveyData from "../data/surveyData";
import "../styles/surveyContainer.css";

const SurveyContainer = ({ group, showIntroduction }) => {
  const navigate = useNavigate();
  const data = surveyData[group];
  const categories = data.categories;
  const totalSurveySteps = categories.reduce(
    (acc, category) => acc + category.questions.length,
    0
  );
  const [currentSurveyCategory, setCurrentSurveyCategory] = useState(
    showIntroduction ? -1 : 0
  );
  const [selectedSurveyOptions, setSelectedSurveyOptions] = useState(
    Array(totalSurveySteps).fill(null)
  );
  const [surveyCategorySums, setSurveyCategorySums] = useState(
    Array(categories.length).fill(0)
  );
  const [surveyError, setSurveyError] = useState("");

  useEffect(() => {
    const newSurveyCategorySums = categories.map((category, categoryIndex) => {
      const categoryStartIndex = categories
        .slice(0, categoryIndex)
        .reduce((acc, category) => acc + category.questions.length, 0);
      const categoryEndIndex = categoryStartIndex + category.questions.length;
      return selectedSurveyOptions
        .slice(categoryStartIndex, categoryEndIndex)
        .reduce(
          (acc, optionIndex, questionIndex) =>
            acc +
            (optionIndex !== null
              ? category.questions[questionIndex].options[optionIndex]
              : 0),
          0
        );
    });
    setSurveyCategorySums(newSurveyCategorySums);
  }, [selectedSurveyOptions, categories]);

  const handleSurveySelectOption = (questionIndex, optionIndex) => {
    const newSelectedSurveyOptions = [...selectedSurveyOptions];
    const categoryStartIndex = categories
      .slice(0, currentSurveyCategory)
      .reduce((acc, category) => acc + category.questions.length, 0);
    newSelectedSurveyOptions[categoryStartIndex + questionIndex] = optionIndex;
    setSelectedSurveyOptions(newSelectedSurveyOptions);
    setSurveyError("");
  };

  const handleSurveyNext = () => {
    if (currentSurveyCategory === -1) {
      setCurrentSurveyCategory(0);
      return;
    }

    const categoryStartIndex = categories
      .slice(0, currentSurveyCategory)
      .reduce((acc, category) => acc + category.questions.length, 0);
    const categoryEndIndex =
      categoryStartIndex + categories[currentSurveyCategory].questions.length;

    if (
      selectedSurveyOptions.slice(categoryStartIndex, categoryEndIndex).includes(null)
    ) {
      setSurveyError("모든 질문에 답변해 주세요.");
      return;
    }

    window.scrollTo(0, 0);

    if (currentSurveyCategory < categories.length - 1) {
      setCurrentSurveyCategory(currentSurveyCategory + 1);
      setSurveyError("");
    } else {
      console.log("답변 데이터:", selectedSurveyOptions);
      console.log("카테고리별 합산 값:", surveyCategorySums);

      localStorage.setItem(
        "surveyCategorySums",
        JSON.stringify(surveyCategorySums)
      );
      localStorage.setItem(
        "selectedSurveyOptions",
        JSON.stringify(selectedSurveyOptions)
      );

      navigate("/test?step=5");
    }
  };

  const handleSurveyPrevious = () => {
    window.scrollTo(0, 0);
    if (currentSurveyCategory > 0) {
      setCurrentSurveyCategory(currentSurveyCategory - 1);
      setSurveyError("");
    } else if (currentSurveyCategory === 0 && showIntroduction) {
      setCurrentSurveyCategory(-1);
    } else {
      navigate("/test?step=3");
    }
  };

  if (currentSurveyCategory === -1) {
    return (
      <StepIntroduction
        onNext={() => setCurrentSurveyCategory(0)}
        onPrevious={() => navigate("/test?step=3")}
      />
    );
  }

  const currentSurveyQuestions = categories[currentSurveyCategory].questions;
  const totalSurveyAnswered = selectedSurveyOptions.filter(
    (option) => option !== null
  ).length;

  const formatCriteria = (criteria) => {
    return criteria.replace(/\((\d)\)/g, '<span class="number">($1)</span>');
  };

  return (
    <div className="survey-container">
      <div className="survey-header">
        <h2>{data.title}</h2>
        <div className="survey-category-box">
          <h3>{categories[currentSurveyCategory].name}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: categories[currentSurveyCategory].scoringCriteria
                .map(formatCriteria)
                .join('<span class="separator"> | </span>'),
            }}
          />
        </div>
        <ProgressBar
          currentStep={totalSurveyAnswered}
          totalSteps={totalSurveySteps}
        />
      </div>
      <SurveyPage
        questions={currentSurveyQuestions}
        selectedOptions={selectedSurveyOptions}
        categoryIndex={currentSurveyCategory}
        onSelectOption={handleSurveySelectOption}
      />
      {surveyError && <p className="error-message">{surveyError}</p>}
      <div className="navigation-buttons">
        <button className="btn-previous" onClick={handleSurveyPrevious}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon-previous"
          >
            <path
              id="Vector"
              d="M3.41498 5.98773L7.36498 2.03773C7.54713 1.84913 7.64793 1.59652 7.64565 1.33433C7.64337 1.07213 7.5382 0.821319 7.3528 0.635911C7.16739 0.450503 6.91657 0.345334 6.65438 0.343055C6.39218 0.340777 6.13958 0.441571 5.95098 0.623729L0.293977 6.28073C0.200791 6.37338 0.126842 6.48354 0.0763807 6.60488C0.025919 6.72621 -5.81741e-05 6.85632 -5.81741e-05 6.98773C-5.81741e-05 7.11914 0.025919 7.24925 0.0763807 7.37058C0.126842 7.49192 0.200791 7.60208 0.293977 7.69473L5.95098 13.3517C6.04322 13.4472 6.15357 13.5234 6.27557 13.5758C6.39758 13.6282 6.5288 13.6558 6.66158 13.657C6.79435 13.6581 6.92603 13.6328 7.04893 13.5826C7.17183 13.5323 7.28348 13.458 7.37737 13.3641C7.47126 13.2702 7.54552 13.1586 7.5958 13.0357C7.64608 12.9128 7.67138 12.7811 7.67023 12.6483C7.66907 12.5155 7.64149 12.3843 7.58908 12.2623C7.53667 12.1403 7.46049 12.03 7.36498 11.9377L3.41498 7.98773H13.001C13.2662 7.98773 13.5205 7.88237 13.7081 7.69484C13.8956 7.5073 14.001 7.25295 14.001 6.98773C14.001 6.72251 13.8956 6.46816 13.7081 6.28062C13.5205 6.09309 13.2662 5.98773 13.001 5.98773H3.41498Z"
              fill="currentColor"
            />
          </svg>
          이전
        </button>
        <button className="btn-next" onClick={handleSurveyNext}>
          다음
          <img src="/images/Vector_w.svg" alt="next" className="icon-next" />
        </button>
      </div>
    </div>
  );
};

export default SurveyContainer;
