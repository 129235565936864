import React, { useState } from "react";
import "../styles/faq.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const questions = [
    {
      question:
        "[사업개요] 유아언어발달검사 및 치료지원 사업은 누가 운영하나요? ",
      answer:
        "본 사업은 <강원특별자치도교육청>이 더 나은 강원교육을 위한 유아 언어발달 지원 강화 및 유보통합 선도교육청 추진 사업의 하나로 진행합니다. 또한, <한림대학교 산학협력단>이 위탁받아 언어청각학부 언어병리학전공에서 사업을 기획/운영하며, <두루바른사회적협동조합>이 운영을 지원하고 있습니다. ",
    },
    {
      question: "[사업개요] 사업은 어떤 절차로 진행되나요? ",
      answer:
        "본 사업은 ‘선별검사 → 심화검사 → 상담·컨설팅 → 치료지원’의 절차로 진행됩니다.<br/>- 선별검사는 언어발달 지연이 의심되는 아동을 심화검사로 연계하기 위한 과정입니다.<br/>- 심화검사는 언어발달 지연이 의심되는 아동을 보다 심층적으로 검사하고, 치료지원 연계 여부를 판단하기 위한 과정입니다.<br/>- 상담·컨설팅은 보호자 또는 교사를 대상으로 심화검사 결과와 치료지원 연계 여부 등을 안내하는 과정입니다.<br/>- 치료지원은 치료가 권고된 아동 대상 8~12회기의 1:1 맞춤형 치료 서비스를 제공하는 과정입니다.",
    },
    {
      question: "[선별검사] 선별검사 참여는 어떻게 하나요?",
      answer:
        "웹 또는 모바일을 통해 [www.gwchild.co.kr] 접속하신 후 기관별 접속 코드와 아동의 기본 정보를 정확하게 입력한 후 확인 버튼을 클릭하면 검사 참여가 가능합니다. ",
    },
    {
      question: "[선별검사] 선별검사 참여기간은 어떻게 되나요?",
      answer:
        "선별검사는 7월 3일(수)부터 7월 16일(화)까지 진행됩니다. 해당 기간 내 선별검사를 진행하여 주시기 바랍니다.",
    },
    {
      question:
        "[선별검사] 선별검사는 어린이집, 유치원에 재원하고 있는 아동 모두 참여가 가능한가요?",
      answer:
        "선별검사는 만 3~5세 아동을 대상으로 합니다. 검사일을 기준의 생활연령을 적용하니 참고하여 주시기 바랍니다.",
    },
    {
      question:
        "[선별검사] 선별검사는 언어발달 지연이 의심되는 아동만 참여하는 건가요?",
      answer:
        "선별검사는 도내 만 3~5세 아동 전체를 대상으로 전수조사 형태로 진행됩니다. 모든 아동이 참여함으로써 보다 정확한 언어발달 상태를 파악하고, 필요한 지원을 제공할 수 있습니다. 일부 아동만 참여할 경우 전체적인 언어발달 상태를 확인하기 어려우므로, 모든 아동의 참여를 권장합니다. 이를 통해 아동의 발달 상황을 조기에 발견하고 지원할 수 있습니다.",
    },
    {
      question: "[선별검사] 바우처 지원을 통해 언어치료를 받고 있는 아동도 선별검사 참여가 가능한가요?",
      answer:
        "바우처 지원을 통해 언어치료를 받고 있는 만 3~5세 아동도 선별검사와 심화검사에 참여 가능합니다. 다만, 선별검사, 심화검사 결과 치료가 권고된 아동의 경우 바우처 종류에 따라서 중복지원으로 치료지원이 제한될 수 있습니다.",
    },
    {
      question: "[선별검사] 선별검사는 누가 작성해야 하나요?",
      answer:
        "선별검사는 해당 아동의 부모 또는 교사가 작성할 수 있습니다. 교사가 작성할 경우 보호자의 동의가 필요합니다.",
    },
    {
      question: "[선별검사] 선별검사는 시간에 상관없이 참여 가능한가요?",
      answer:
        "선별검사는 검사 기간 내에 시간 제약 없이 참여할 수 있습니다.",
    },
    {
      question: "[선별검사] 선별검사를 하려면 무엇이 필요한가요?",
      answer:
        "선별검사 참여를 위해서는 각 기관(유치원, 어린이집)별 접속 코드가 필요합니다. 접속 코드는 해당 기관에 문의해 주시기 바랍니다.",
    },
    {
      question: "[선별검사] 선별검사는 총 몇 문항으로 구성되어 있나요?",
      answer:
        "본 검사는 2단계로 진행됩니다. 1단계에서는 공통 문항으로 ‘한국판 영유아 발달선별검사(K-DST)’ 48문항을 실시합니다. 2단계에서는 연령에 따라 ‘유아 언어 및 의사소통 발달 선별 체크리스트(만 3~4세용)’ 34문항, ‘언어기반 문해기초 선별 체크리스트(만 5세용)’ 30문항을 실시하게되며, 총 검사 소요시간은 10~15분입니다. ",
    },
    {
      question: "[선별검사] 선별검사 제출이 완료된 후 작성 정보를 수정할 수 있나요?",
      answer:
        "제출이 완료되면 작성한 내용 수정은 불가합니다. 작성 중 수정은 가능하니, 제출 완료 버튼을 누르기 전 한 번 더 확인하여 주시기 바랍니다.",
    },
    {
      question: "[선별검사] 유치원, 어린이집에서 기관 참여 신청을 하지 않았다면 선별검사에 참여할 수 없나요?",
      answer:
        "A. 참여 신청을 하지 않은 기관은 선별검사에 참여할 수 없습니다. 1차 참여기관 모집은 지난 6월 21일(금)에 접수가 마감되었습니다. <br/>2차 참여기관 모집은 7월 12일(금), 오전 12시까지 접수받을 예정입니다. 미신청 기관은 해당 기간까지 접수를 완료하면, 이후 기관별로 선별검사 참여 안내를 드릴 예정입니다.<br/>- (유치원) 신청링크 : <a href=\"https://forms.gle/9h9JbAxdG4EjHdrc7\" target=\"_blank\">https://forms.gle/9h9JbAxdG4EjHdrc7</a><br/>- (어린이집) 신청링크 : <a href=\"https://forms.gle/yXL9weH2srhihUds5\" target=\"_blank\">https://forms.gle/yXL9weH2srhihUds5</a>",
    },
    {
      question: "[심화검사] 심화검사는 선별검사에 참여한 아동 모두가 대상인가요?",
      answer:
        "심화검사는 선별검사를 통해 언어발달 지연이 의심되는 아동을 대상으로 진행합니다.",
    },
    {
      question: "[심화검사] 심화검사는 어떤 방식으로 진행하나요?",
      answer:
        "심화검사는 언어발달 지연이 의심되는 아동과 검사자가 1:1 대면으로 약 1시간 30분가량 검사 진행합니다. 검사 진행을 맡은 전문가 매칭 후에 검사장소와 시간을 조율하여 안내 드릴 예정입니다.",
    },
    {
      question: "[심화검사] 심화검사는 어디에서 진행하나요?",
      answer:
        "심화검사는 해당 아동이 속한 기관에서 진행할 예정입니다. 다만, 기관 내 공간이 여의치 않을 경우 인근 관공서 및 치료센터 등의 공간을 활용할 계획입니다. ",
    },
    {
      question: "[심화검사] 심화검사 진행 시 부모 또는 교사가 동석해야 하나요?",
      answer:
        "심화검사 진행 시 부모 또는 교사의 동석이 필수사항은 아닙니다. 다만, 상황에 따라서 동석이 필요할 수 있으니 이점 참고 바랍니다.",
    },
    {
      question: "[심화검사] 심화검사 결과는 어떻게 확인할 수 있나요?",
      answer:
        "선별검사 이후 검사 결과에 대하여 검사자가 부모 또는 교사 대상 컨설팅을 진행합니다. 컨설팅은 검사 결과와 치료지원에 대한 내용을 포함하여 대면 또는 유선, 온라인 등 가능한 방법을 선택하여 진행할 예정입니다. ",
    },
    {
      question: "[치료지원] 치료지원은 어떤 방식으로 진행되나요?",
      answer:
        "치료지원이 결정된 아동은 아동이 거주하는 지역 또는 인근 지역의 협력 치료기관과 매칭되며 해당 기관으로 아동이 방문하여 1:1 대면으로 진행합니다. 개별 맞춤형 언어치료는 8회기~12회기에 걸쳐 지원될 예정입니다. ",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq">
      <div className="accordion">
        {questions.map((item, index) => (
          <div
            key={index}
            className={`accordion-item ${
              activeIndex === index ? "active" : ""
            }`}
          >
            <div
              className="accordion-title"
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <span className="accordion-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>
            <div
              className={`accordion-content ${
                activeIndex === index ? "show" : ""
              }`}
              dangerouslySetInnerHTML={{ __html: item.answer }} // 여기서 HTML을 직접 렌더링
            ></div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
