const checklistData = {
    group36_59: {
        title: '유아 언어 및 의사소통 발달 선별 체크리스트',
        description: '본 검사는 3, 4세 유아를 위한 언어발달 선별검사입니다. K-DST 결과와 함께 더 자세한 심화언어검사가 필요한 유아를 확인하기 위한 목적으로 실시합니다.',
        categories: [
            {
                name: '의사소통 행동',
                scoringCriteria: ['안함: 전혀 하지 않음', '가끔: 일관되지 않거나 간혹(이따금) 함', '자주: 대부분 일관되게 함'],
                questions: [
                    {
                        question: '말을 시키거나 질문하면 대답한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '몸짓이나 행동보다는 말로 의사소통을 한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '자신의 욕구를 말로 표현한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '친구들과의 대화에 관심을 보인다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '좋아하거나 관심있는 것에 대해서 먼저 표현한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '상대방에게 허락을 구하는 말을 한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '궁금한 것이 있으면 질문한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '상대방이 물으면 말을 고쳐서 다시 말한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    }
                ]
            },
            {
                name: '언어 이해',
                scoringCriteria: ['안함: 전혀 하지 않음', '가끔: 일관되지 않거나 간혹(이따금) 함', '자주: 대부분 일관되게 함'],
                questions: [
                    {
                        question: '한번에 2가지 사물이나 행동이 포함된 지시를 이해한다. (예: 컵이랑 숟가락 가져와, 옷 입고 놀이터 가자).',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '신체 부위 이름을 듣고 정확하게 가리킨다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '위치(위/아래, 앞/뒤)를 나타내는 말을 이해한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '시간(먼저, 나중에, 이따가)을 나타내는 말을 이해한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '"언제?"라는 질문을 이해한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '짧은 이야기(예: 토끼와 거북이)를 들려주면 끝까지 듣는다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '정확한 답을 못하더라도 "왜?"라는 질문을 이해한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '이유나 조건을 설명하며 이해한다. (예: 여기 올라가서 다쳤어, 아가 밀면 아프잖아.)',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    }
                ]
            },
            {
                name: '언어 표현',
                scoringCriteria: ['안함: 전혀 하지 않음', '가끔: 일관되지 않거나 간혹(이따금) 함', '자주: 대부분 일관되게 함'],
                questions: [
                    {
                        question: '이름이나 나이를 물으면 말한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '어디 사는지 물으면 사는 곳(장소)을 말한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '10~20까지 수를 센다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '평소 3-4단어로 연결된 문장을 말한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '"왜?"라는 질문을 한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '과거(예: 먹었어, 갔어)나 미래(예: 먹을 거야, 갈거야)시제를 사용한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '연결어미로 문장을 이어서 길게 말한다. (예: 형아랑 놀이터에 가서 꼭꼭숨어라 하고 왔어)',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '최근(예: 집, 원에서 놀이시간)에 있었던 일에 대해 간단히 말한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    }
                ]
            },
            {
                name: '발음과 유창성',
                scoringCriteria: ['안함: 전혀 하지 않음', '가끔: 일관되지 않거나 간혹(이따금) 함', '자주: 대부분 일관되게 함'],
                questions: [
                    {
                        question: '말을 할 때 대부분의 모음은 정확하게 발음한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '말을 할 때 ㄷ, ㄸ, ㅌ, ㄱ, ㄲ, ㅋ 말소리를 정확하게 발음한다. (예: 다리, 빨대, 딸기, 토끼, 가, 여기, 까까, 코끼리)',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '긴 단어(4음절 이상)의 글자수를 줄이지 않고 발음한다. (예: 할아버지 → 하삐, 하부지, 아이스크림 → 아크임, 엘리베이터 → 에비터)',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '받침소리를 정확하게 발음한다. (예: 딸기, 김밥)',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '말을 할 때 ㅈ, ㅉ, ㅊ 말소리를 정확하게 발음한다. (예: 치즈, 춤춰여, 짜장면)',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '말을 할 때 ㅅ, ㅆ 말소리를 정확하게 발음한다. (예: 시소, 선생님)',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '말을 할 때 바꾸지 않고 ㄹ 말소리를 정확하게 발음한다. (예: 라면, 머리, 올라가)',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '말을 하는 동안(100단어 정도) 1-2번 말을 더듬는다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '같은 말이나 소리를 반복하거나 수정하지 않고 말한다. (예: 가가가가방, 엄마엄마엄마가).',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    },
                    {
                        question: '말을 할 때 얼굴이나 몸에 힘을 주지 않고 편안하게 말한다.',
                        options: ['안함', '가끔', '자주'],
                        image: null
                    }
                ]
            }
        ]
    },
    group60_71: {
        title: '언어기반 문해기초 선별 체크리스트',
        description: '본 검사는 5세 유아를 위한 언어 및 문해 선별 검사입니다. K-DST 결과와 함께 더 자세한 심화언어검사가 필요한 유아를 확인하기 위한 목적으로 실시합니다.',
        categories: [
            {
                name: '언어 이해 및 표현',
                scoringCriteria: ['예', '아니오', '모르겠음: 유아의 수행력을 판단하기 어려운 경우'],
                questions: [
                    {
                        question: '두 가지 이상의 지시사항을 빠뜨리지 않고 모두 반응할 수 있나요? (예: 장난감을 제자리에 두고 교실로 오세요.)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '행동적인 예시나 시각적인 추가 단서가 없이는 말을 이해하기 어려운가요? (예: 손으로 가리켜 보여주거나, 상황적 단서를 부가적으로 말해주어야 해요.)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '\'이다\', \'왜\', \'어떻게\'가 포함된 간단한 질문을 이해하고 대답할 수 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '\'-전\', \'-후\', \'-동안\'과 같은 시간 관련 표현이 포함된 말을 듣고 지시 따르기를 순서에 맞추어 할 수 있나요? (예: OO하기 전에 OO를 해요, OO하는 동안 OO를 해요)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '\'오른쪽·왼쪽\', \'위·아래\', \'앞·뒤\'와 같은 공간 관련 표현이 포함된 말을 듣고 방향에 맞게 행동하거나 지시따르기를 할 수 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '문장을 말할 때 4~5개 정도의 단어와 연결어미(예: ~고, ~면, ~어서/아서)를 사용하여 복문으로 말할 수 있나요? (예: 친구가 와서 아까 이거 그렸어요.)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '아동은 동일한 상황이나 대상에 대해 다양한 단어를 사용하고 표현할 수 있나요? (예: \'좋다\'라는 한가지 말 대신 행복하다, 기쁘다, 친절하다, 친하다, 착하다 등 다양한 표현을 사용해요.)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '사건·대상을 설명할 때 중요한 내용을 빠뜨리지 않고 말할 수 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '\'눈앞\'에서 일어나는 활동이나 사건에 대해 앞 뒤 순서를 맞추어 이야기할 수 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '길게 설명하지만 장황하여 내용을 이해하기 어려울 때가 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '다른 친구나 선생님이 말하는 도중 부적절하게 끼어드나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '다른 친구나 선생님이 말을 듣지 않고 혼자만 계속 말할려고 하나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '대화를 나눌 때 동일한 주제로 상대방과 여러 번 주고받으면서 말할 수 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '상대방의 이야기를 이해하지 못할 때 다시 말해달라고 하거나, 반대로 상대가 이해하지 못했을 때 다시 설명할 수 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    }
                ]
            },
            {
                name: '말 및 문해기초',
                scoringCriteria: ['예', '아니오', '모르겠음: 유아의 수행력을 판단하기 어려운 경우'],
                questions: [
                    {
                        question: '말을 할 때 단어가 생각나지 않아서 다른 말로 바꾸어 말하기도 하나요? (예: \'양\'을 \'염소\'라고 하거나, \'그거 있잖아 메에 하는 동물\'이라고 설명해요.)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '말을 시작할 때 머뭇거리나 잠시 멈춤, 길게 연장하기, 단어의 일부나 전체를 반복하는 일이 있나요? (예: 어~~~, 어(쉼), 내 내 내가)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '구체적인 단어 대신 애매한 표현이나 대용어를 사용하여 말을 하나요? (예: 이거, 그거, 그 때)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '선생님의 지시사항을 기억하는데 어려움이 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '새로운 친구나 사물의 이름을 배우고 기억하는데 어려움이 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '길거나 복잡한 단어를 어렵지 않게 발음할 수 있나요? (예: 헬리콥터, 스케치북, 아이스크림, 엘리베이터)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '단어나 이름을 잘못 들어서 그로 인해 발음도 틀리나요? (예: \'곰\'을 \'공\'으로 잘못 들어서 \'공\'으로 발음해요.)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '말하거나 대답할 때 틀리는 발음이 거의 없나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '똑같은 소리로 시작되는 단어 찾기 놀이를 할 수 있나요? (예: \'모 모 모\' 자로 시작되는 말은? 모기, 모자, 모래, 모두)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '유아가 음절 수준의 말소리 합하기 놀이를 할 수 있나요? (예: \'비\'에 \'누\'를 합하면 어떤 소리가 될까요?에 \'비누\'라고 답해요.)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '유아가 \'음절체+종성\' 수준의 말소리 합하기 놀이를 할 수 있나요? (예: \'코\'에 \'ㅇ\'을 합하면 어떤 소리가 될까요?에 \'콩\'이라고 답해요.)',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '책을 볼 때 앞에서 뒤로 넘기거나 위에서 아래로 살피면서 볼 수 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '1-2음절의 간단한 글자를 읽을 수 있나요? (예: 소, 컵, 개미, 나비, 비누, 가방, 감자(읽을 때 글자와 소리가 같은 단어))',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '적힌 글자를 보고 따라 쓰기를 할 수 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '자신의 이름 일부 또는 전체를 보지 않고 쓸 수 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    },
                    {
                        question: '스스로 적을 수 있는 낱말이 여러 개 있나요?',
                        options: ['예', '아니오', '모르겠음'],
                        image: null
                    }
                ]
            },
        ]
    }
};

export default checklistData;
