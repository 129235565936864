import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import StepOverview from "../components/StepOverview";
import StepConsent from "../components/StepConsent";
import StepBasicInfo from "../components/StepBasicInfo";
import SurveyContainer from "../components/SurveyContainer";
import ChecklistContainer from "../components/ChecklistContainer";
import StepReport from "../components/StepReport";
import "../styles/testPage.css";

const steps = [
  { id: 1, name: "선별검사 개요", component: StepOverview },
  { id: 2, name: "개인정보 동의", component: StepConsent },
  { id: 3, name: "기본정보 입력", component: StepBasicInfo },
  { id: 4, name: "K-DST 실시", component: SurveyContainer },
  { id: 5, name: "체크리스트 실시", component: ChecklistContainer },
  { id: 6, name: "결과보고서 출력", component: StepReport },
];

const getAgeGroup = (ageInMonths) => {
  if (ageInMonths >= 30 && ageInMonths <= 41) return "group36_41";
  if (ageInMonths >= 42 && ageInMonths <= 47) return "group42_47";
  if (ageInMonths >= 48 && ageInMonths <= 53) return "group48_53";
  if (ageInMonths >= 54 && ageInMonths <= 59) return "group54_59";
  if (ageInMonths >= 60 && ageInMonths <= 65) return "group60_65";
  if (ageInMonths >= 66 && ageInMonths <= 78) return "group66_71";
  return null;
};

const getChecklistAgeGroup = (ageInMonths) => {
  if (ageInMonths >= 30 && ageInMonths <= 59) return "group36_59";
  if (ageInMonths >= 60 && ageInMonths <= 78) return "group60_71";
  return null;
};

const TestPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialStep = parseInt(queryParams.get("step")) || 1;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [ageGroup, setAgeGroup] = useState(null);
  const [checklistAgeGroup, setChecklistAgeGroup] = useState(null);

  useEffect(() => {
    setCurrentStep(initialStep);
  }, [initialStep]);

  useEffect(() => {
    const ageInMonths = parseInt(localStorage.getItem("ageInMonths"), 10);
    if (currentStep === 4) {
      const group = getAgeGroup(ageInMonths);
      setAgeGroup(group);
    }
    if (currentStep === 5) {
      const group = getChecklistAgeGroup(ageInMonths);
      setChecklistAgeGroup(group);
    }
  }, [currentStep]);

  const CurrentComponent = steps.find(
    (step) => step.id === currentStep
  ).component;

  return (
    <div className="test-container">
      <div className="test-page">
        <div className="step-navigation">
          {steps.map((step) => (
            <div
              key={step.id}
              className={`step-item ${step.id === currentStep ? "active" : ""}`}
            >
              <button 
                className={`step-button ${step.id === currentStep ? "active" : ""}`} 
                disabled
              >
                {step.name}
              </button>
            </div>
          ))}
        </div>
        <div className="step-content">
          {currentStep === 4 ? (
            ageGroup ? (
              <SurveyContainer group={ageGroup} showIntroduction={true} />
            ) : (
              <p>해당 연령 그룹에 대한 설문이 없습니다.</p>
            )
          ) : currentStep === 5 ? (
            checklistAgeGroup ? (
              <ChecklistContainer group={checklistAgeGroup} showIntroduction={true} />
            ) : (
              <p>해당 연령 그룹에 대한 체크리스트가 없습니다.</p>
            )
          ) : (
            <CurrentComponent />
          )}
        </div>
      </div>
    </div>
  );
};

export default TestPage;