import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/header.css';
import { Menu } from 'lucide-react';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="header-container">
                <div className="header-left">
                    <Link to="/" className="header-banner">
                        <img src="/images/mini_banner.svg" alt="로고" className="header-logo" />
                        <h1><span className="headline">강원특별자치도교육청</span><br />2024년 유아 언어발달 검사 및 치료지원 사업</h1>
                    </Link>
                </div>
                <div className="header-right">
                    <button className="menu-toggle" onClick={toggleMenu}>
                        <Menu size={24} />
                    </button>
                    <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
                        <Link to="/" onClick={toggleMenu}>처음 화면</Link>
                        <Link to="/project" onClick={toggleMenu}>사업 소개</Link>
                        <Link to="/test" onClick={toggleMenu}>선별검사 실시</Link>
                        <Link to="/faq" onClick={toggleMenu}>자주 묻는 질문</Link>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;