import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/homepageContents.css";

const HomepageContents = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/test"); 
  };

  return (
    <div className="homepage-contents">
      <div className="homepage-body">
        <div className="homepage-image">
          <img src="/images/main.png" alt="메인 이미지" />
        </div>
        <div className="home-message">
          <h1>"모든 아이들에게 교육의 평등한 출발선을 제공합니다."</h1>
        </div>
      </div>
      <div className="view-only-mobile">
        <button onClick={handleButtonClick}>선별검사 실시</button>
      </div>
      <div className="homepage-text">
        <h1>강원특별자치도교육청 유아언어발달검사 및 치료지원 사업</h1>
        <h3>언어는 인간의 주된 의사소통과 인지 및 학습 수단으로 중요합니다.</h3>
        <h3>유아기 언어발달 지연은 사회적 적응과 인지 및 학습에 주요 요인으로, 이후 학령기까지 지속적으로 영향을 미칩니다.</h3>
        <h3>'유아언어발달사업'은 학령전기 아동의 언어발달 지연을 조기 선별하고, 개입하여 예방적 차원에서 아동의 언어발달과 학습 기반 구축을 지원합니다.</h3>
      </div>
      <div className="youtube-embed">
        <iframe
          src="https://www.youtube.com/embed/wY5ElIwZQBI?si=ZrkqRFdSF9xlz_Gm"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default HomepageContents;