export const percentileData = {
    "30-41개월": {
      대근육운동: [
        { rawScore: 11, percentile: 1 },
        { rawScore: 12, percentile: 2.25 },
        { rawScore: 13, percentile: 6 },
        { rawScore: 14, percentile: 9 },
        { rawScore: 15, percentile: 13 },
        { rawScore: 16, percentile: 16 },
        { rawScore: 17, percentile: 26 },
        { rawScore: 18, percentile: 35 },
        { rawScore: 19, percentile: 45 },
        { rawScore: 20, percentile: 55 },
        { rawScore: 21, percentile: 65 },
        { rawScore: 22, percentile: 74 },
        { rawScore: 23, percentile: 84 },
        { rawScore: 24, percentile: 92 },
      ],
      소근육운동: [
        { rawScore: 9, percentile: 1 },
        { rawScore: 10, percentile: 2.25 },
        { rawScore: 11, percentile: 5 },
        { rawScore: 12, percentile: 8 },
        { rawScore: 13, percentile: 11 },
        { rawScore: 14, percentile: 13 },
        { rawScore: 15, percentile: 16 },
        { rawScore: 16, percentile: 25 },
        { rawScore: 17, percentile: 33 },
        { rawScore: 18, percentile: 42 },
        { rawScore: 19, percentile: 50 },
        { rawScore: 20, percentile: 59 },
        { rawScore: 21, percentile: 67 },
        { rawScore: 22, percentile: 76 },
        { rawScore: 23, percentile: 84 },
        { rawScore: 24, percentile: 92 },
      ],
      인지: [
        { rawScore: 9, percentile: 1 },
        { rawScore: 10, percentile: 2.25 },
        { rawScore: 11, percentile: 5 },
        { rawScore: 12, percentile: 7 },
        { rawScore: 13, percentile: 9 },
        { rawScore: 14, percentile: 11 },
        { rawScore: 15, percentile: 14 },
        { rawScore: 16, percentile: 16 },
        { rawScore: 17, percentile: 26 },
        { rawScore: 18, percentile: 35 },
        { rawScore: 19, percentile: 45 },
        { rawScore: 20, percentile: 55 },
        { rawScore: 21, percentile: 65 },
        { rawScore: 22, percentile: 74 },
        { rawScore: 23, percentile: 84 },
        { rawScore: 24, percentile: 92 },
      ],
      언어: [
        { rawScore: 6, percentile: 1 },
        { rawScore: 7, percentile: 2.25 },
        { rawScore: 8, percentile: 3 },
        { rawScore: 9, percentile: 5 },
        { rawScore: 10, percentile: 6 },
        { rawScore: 11, percentile: 7 },
        { rawScore: 12, percentile: 8 },
        { rawScore: 13, percentile: 9 },
        { rawScore: 14, percentile: 10 },
        { rawScore: 15, percentile: 11 },
        { rawScore: 16, percentile: 13 },
        { rawScore: 17, percentile: 14 },
        { rawScore: 18, percentile: 15 },
        { rawScore: 19, percentile: 16 },
        { rawScore: 20, percentile: 30 },
        { rawScore: 21, percentile: 43 },
        { rawScore: 22, percentile: 57 },
        { rawScore: 23, percentile: 70 },
        { rawScore: 24, percentile: 84 },
      ],
      사회성: [
        { rawScore: 11, percentile: 1 },
        { rawScore: 12, percentile: 2.25 },
        { rawScore: 13, percentile: 5 },
        { rawScore: 14, percentile: 8 },
        { rawScore: 15, percentile: 11 },
        { rawScore: 16, percentile: 13 },
        { rawScore: 17, percentile: 16 },
        { rawScore: 18, percentile: 26 },
        { rawScore: 19, percentile: 35 },
        { rawScore: 20, percentile: 45 },
        { rawScore: 21, percentile: 55 },
        { rawScore: 22, percentile: 65 },
        { rawScore: 23, percentile: 74 },
        { rawScore: 24, percentile: 84 },
      ],
      자조: [
        { rawScore: 10, percentile: 1 },
        { rawScore: 11, percentile: 2.25 },
        { rawScore: 12, percentile: 6 },
        { rawScore: 13, percentile: 9 },
        { rawScore: 14, percentile: 13 },
        { rawScore: 15, percentile: 16 },
        { rawScore: 16, percentile: 25 },
        { rawScore: 17, percentile: 33 },
        { rawScore: 18, percentile: 42 },
        { rawScore: 19, percentile: 50 },
        { rawScore: 20, percentile: 59 },
        { rawScore: 21, percentile: 67 },
        { rawScore: 22, percentile: 76 },
        { rawScore: 23, percentile: 84 },
        { rawScore: 24, percentile: 92 },
      ],
    },
    "42-47개월": {
        대근육운동: [
          { rawScore: 13, percentile: 1 },
          { rawScore: 14, percentile: 2.25 },
          { rawScore: 15, percentile: 6 },
          { rawScore: 16, percentile: 9 },
          { rawScore: 17, percentile: 13 },
          { rawScore: 18, percentile: 16 },
          { rawScore: 19, percentile: 27 },
          { rawScore: 20, percentile: 39 },
          { rawScore: 21, percentile: 50 },
          { rawScore: 22, percentile: 61 },
          { rawScore: 23, percentile: 73 },
          { rawScore: 24, percentile: 84 },
        ],
        소근육운동: [
          { rawScore: 12, percentile: 1 },
          { rawScore: 13, percentile: 2.25 },
          { rawScore: 14, percentile: 5 },
          { rawScore: 15, percentile: 8 },
          { rawScore: 16, percentile: 11 },
          { rawScore: 17, percentile: 16 },
          { rawScore: 18, percentile: 26 },
          { rawScore: 19, percentile: 35 },
          { rawScore: 20, percentile: 45 },
          { rawScore: 21, percentile: 55 },
          { rawScore: 22, percentile: 65 },
          { rawScore: 23, percentile: 74 },
          { rawScore: 24, percentile: 84 },
        ],
        인지: [
          { rawScore: 12, percentile: 1 },
          { rawScore: 13, percentile: 2.25 },
          { rawScore: 14, percentile: 5 },
          { rawScore: 15, percentile: 8 },
          { rawScore: 16, percentile: 11 },
          { rawScore: 17, percentile: 13 },
          { rawScore: 18, percentile: 16 },
          { rawScore: 19, percentile: 27 },
          { rawScore: 20, percentile: 39 },
          { rawScore: 21, percentile: 50 },
          { rawScore: 22, percentile: 61 },
          { rawScore: 23, percentile: 73 },
          { rawScore: 24, percentile: 84 },
        ],
        언어: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 4 },
          { rawScore: 14, percentile: 6 },
          { rawScore: 15, percentile: 8 },
          { rawScore: 16, percentile: 10 },
          { rawScore: 17, percentile: 12 },
          { rawScore: 18, percentile: 14 },
          { rawScore: 19, percentile: 16 },
          { rawScore: 20, percentile: 30 },
          { rawScore: 21, percentile: 43 },
          { rawScore: 22, percentile: 57 },
          { rawScore: 23, percentile: 70 },
          { rawScore: 24, percentile: 84 },
        ],
        사회성: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 5 },
          { rawScore: 14, percentile: 8 },
          { rawScore: 15, percentile: 16 },
          { rawScore: 16, percentile: 13 },
          { rawScore: 17, percentile: 16 },
          { rawScore: 18, percentile: 27 },
          { rawScore: 19, percentile: 39 },
          { rawScore: 20, percentile: 50 },
          { rawScore: 21, percentile: 61 },
          { rawScore: 22, percentile: 73 },
          { rawScore: 23, percentile: 84 },
          { rawScore: 24, percentile: 92 },
        ],
        자조: [
          { rawScore: 12, percentile: 1 },
          { rawScore: 13, percentile: 2.25 },
          { rawScore: 14, percentile: 5 },
          { rawScore: 15, percentile: 8 },
          { rawScore: 16, percentile: 11 },
          { rawScore: 17, percentile: 13 },
          { rawScore: 18, percentile: 16 },
          { rawScore: 19, percentile: 27 },
          { rawScore: 20, percentile: 39 },
          { rawScore: 21, percentile: 50 },
          { rawScore: 22, percentile: 61 },
          { rawScore: 23, percentile: 73 },
          { rawScore: 24, percentile: 84 },
        ],
      },
      "48-53개월": {
        대근육운동: [
          { rawScore: 13, percentile: 1 },
          { rawScore: 14, percentile: 2.25 },
          { rawScore: 15, percentile: 6 },
          { rawScore: 16, percentile: 9 },
          { rawScore: 17, percentile: 13 },
          { rawScore: 18, percentile: 16 },
          { rawScore: 19, percentile: 27 },
          { rawScore: 20, percentile: 39 },
          { rawScore: 21, percentile: 50 },
          { rawScore: 22, percentile: 61 },
          { rawScore: 23, percentile: 73 },
          { rawScore: 24, percentile: 84 },
        ],
        소근육운동: [
          { rawScore: 12, percentile: 1 },
          { rawScore: 13, percentile: 2.25 },
          { rawScore: 14, percentile: 5 },
          { rawScore: 15, percentile: 8 },
          { rawScore: 16, percentile: 11 },
          { rawScore: 17, percentile: 13 },
          { rawScore: 18, percentile: 16 },
          { rawScore: 19, percentile: 27 },
          { rawScore: 20, percentile: 39 },
          { rawScore: 21, percentile: 50 },
          { rawScore: 22, percentile: 61 },
          { rawScore: 23, percentile: 73 },
          { rawScore: 24, percentile: 84 },
        ],
        인지: [
          { rawScore: 13, percentile: 1 },
          { rawScore: 14, percentile: 2.25 },
          { rawScore: 15, percentile: 6 },
          { rawScore: 16, percentile: 9 },
          { rawScore: 17, percentile: 13 },
          { rawScore: 18, percentile: 16 },
          { rawScore: 19, percentile: 27 },
          { rawScore: 20, percentile: 39 },
          { rawScore: 21, percentile: 50 },
          { rawScore: 22, percentile: 61 },
          { rawScore: 23, percentile: 73 },
          { rawScore: 24, percentile: 84 },
        ],
        언어: [
          { rawScore: 12, percentile: 1 },
          { rawScore: 13, percentile: 2.25 },
          { rawScore: 14, percentile: 5 },
          { rawScore: 15, percentile: 7 },
          { rawScore: 16, percentile: 9 },
          { rawScore: 17, percentile: 11 },
          { rawScore: 18, percentile: 14 },
          { rawScore: 19, percentile: 16 },
          { rawScore: 20, percentile: 30 },
          { rawScore: 21, percentile: 43 },
          { rawScore: 22, percentile: 57 },
          { rawScore: 23, percentile: 70 },
          { rawScore: 24, percentile: 84 },
        ],
        사회성: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 6 },
          { rawScore: 14, percentile: 9 },
          { rawScore: 15, percentile: 13 },
          { rawScore: 16, percentile: 16 },
          { rawScore: 17, percentile: 26 },
          { rawScore: 18, percentile: 35 },
          { rawScore: 19, percentile: 45 },
          { rawScore: 20, percentile: 55 },
          { rawScore: 21, percentile: 65 },
          { rawScore: 22, percentile: 74 },
          { rawScore: 23, percentile: 84 },
          { rawScore: 24, percentile: 92 },
        ],
        자조: [
          { rawScore: 14, percentile: 1 },
          { rawScore: 15, percentile: 2.25 },
          { rawScore: 16, percentile: 5 },
          { rawScore: 17, percentile: 8 },
          { rawScore: 18, percentile: 11 },
          { rawScore: 19, percentile: 13 },
          { rawScore: 20, percentile: 16 },
          { rawScore: 21, percentile: 33 },
          { rawScore: 22, percentile: 50 },
          { rawScore: 23, percentile: 67 },
          { rawScore: 24, percentile: 84 },
        ],
      },
      "54-59개월": {
        대근육운동: [
          { rawScore: 14, percentile: 1 },
          { rawScore: 15, percentile: 2.25 },
          { rawScore: 16, percentile: 7 },
          { rawScore: 17, percentile: 11 },
          { rawScore: 18, percentile: 16 },
          { rawScore: 19, percentile: 27 },
          { rawScore: 20, percentile: 39 },
          { rawScore: 21, percentile: 50 },
          { rawScore: 22, percentile: 61 },
          { rawScore: 23, percentile: 73 },
          { rawScore: 24, percentile: 84 },
        ],
        소근육운동: [
          { rawScore: 14, percentile: 1 },
          { rawScore: 15, percentile: 2.25 },
          { rawScore: 16, percentile: 5 },
          { rawScore: 17, percentile: 8 },
          { rawScore: 18, percentile: 11 },
          { rawScore: 19, percentile: 13 },
          { rawScore: 20, percentile: 16 },
          { rawScore: 21, percentile: 33 },
          { rawScore: 22, percentile: 50 },
          { rawScore: 23, percentile: 67 },
          { rawScore: 24, percentile: 84 },
        ],
        인지: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 5 },
          { rawScore: 14, percentile: 8 },
          { rawScore: 15, percentile: 11 },
          { rawScore: 16, percentile: 13 },
          { rawScore: 17, percentile: 16 },
          { rawScore: 18, percentile: 26 },
          { rawScore: 19, percentile: 35 },
          { rawScore: 20, percentile: 45 },
          { rawScore: 21, percentile: 55 },
          { rawScore: 22, percentile: 65 },
          { rawScore: 23, percentile: 74 },
          { rawScore: 24, percentile: 84 },
        ],
        언어: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 5 },
          { rawScore: 14, percentile: 8 },
          { rawScore: 15, percentile: 11 },
          { rawScore: 16, percentile: 13 },
          { rawScore: 17, percentile: 16 },
          { rawScore: 18, percentile: 26 },
          { rawScore: 19, percentile: 35 },
          { rawScore: 20, percentile: 45 },
          { rawScore: 21, percentile: 55 },
          { rawScore: 22, percentile: 65 },
          { rawScore: 23, percentile: 74 },
          { rawScore: 24, percentile: 84 },
        ],
        사회성: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 6 },
          { rawScore: 14, percentile: 9 },
          { rawScore: 15, percentile: 13 },
          { rawScore: 16, percentile: 16 },
          { rawScore: 17, percentile: 26 },
          { rawScore: 18, percentile: 35 },
          { rawScore: 19, percentile: 45 },
          { rawScore: 20, percentile: 55 },
          { rawScore: 21, percentile: 65 },
          { rawScore: 22, percentile: 74 },
          { rawScore: 23, percentile: 84 },
          { rawScore: 24, percentile: 92 },
        ],
        자조: [
          { rawScore: 15, percentile: 1 },
          { rawScore: 16, percentile: 2.25 },
          { rawScore: 17, percentile: 6 },
          { rawScore: 18, percentile: 9 },
          { rawScore: 19, percentile: 13 },
          { rawScore: 20, percentile: 16 },
          { rawScore: 21, percentile: 33 },
          { rawScore: 22, percentile: 50 },
          { rawScore: 23, percentile: 67 },
          { rawScore: 24, percentile: 84 },
        ],
      },
      "60-65개월": {
        대근육운동: [
          { rawScore: 14, percentile: 1 },
          { rawScore: 15, percentile: 2.25 },
          { rawScore: 16, percentile: 6 },
          { rawScore: 17, percentile: 9 },
          { rawScore: 18, percentile: 13 },
          { rawScore: 19, percentile: 16 },
          { rawScore: 20, percentile: 30 },
          { rawScore: 21, percentile: 43 },
          { rawScore: 22, percentile: 57 },
          { rawScore: 23, percentile: 70 },
          { rawScore: 24, percentile: 84 },
        ],
        소근육운동: [
          { rawScore: 15, percentile: 1 },
          { rawScore: 16, percentile: 2.25 },
          { rawScore: 17, percentile: 5 },
          { rawScore: 18, percentile: 8 },
          { rawScore: 19, percentile: 11 },
          { rawScore: 20, percentile: 13 },
          { rawScore: 21, percentile: 16 },
          { rawScore: 22, percentile: 23 },
          { rawScore: 23, percentile: 45 },
          { rawScore: 24, percentile: 84 },
        ],
        인지: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 5 },
          { rawScore: 14, percentile: 8 },
          { rawScore: 15, percentile: 11 },
          { rawScore: 16, percentile: 13 },
          { rawScore: 17, percentile: 16 },
          { rawScore: 18, percentile: 26 },
          { rawScore: 19, percentile: 35 },
          { rawScore: 20, percentile: 45 },
          { rawScore: 21, percentile: 55 },
          { rawScore: 22, percentile: 65 },
          { rawScore: 23, percentile: 74 },
          { rawScore: 24, percentile: 84 },
        ],
        언어: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 5 },
          { rawScore: 14, percentile: 8 },
          { rawScore: 15, percentile: 11 },
          { rawScore: 16, percentile: 13 },
          { rawScore: 17, percentile: 16 },
          { rawScore: 18, percentile: 26 },
          { rawScore: 19, percentile: 35 },
          { rawScore: 20, percentile: 45 },
          { rawScore: 21, percentile: 55 },
          { rawScore: 22, percentile: 65 },
          { rawScore: 23, percentile: 74 },
          { rawScore: 24, percentile: 84 },
        ],
        사회성: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 6 },
          { rawScore: 14, percentile: 9 },
          { rawScore: 15, percentile: 13 },
          { rawScore: 16, percentile: 16 },
          { rawScore: 17, percentile: 26 },
          { rawScore: 18, percentile: 35 },
          { rawScore: 19, percentile: 45 },
          { rawScore: 20, percentile: 55 },
          { rawScore: 21, percentile: 65 },
          { rawScore: 22, percentile: 74 },
          { rawScore: 23, percentile: 84 },
          { rawScore: 24, percentile: 92 },
        ],
        자조: [
          { rawScore: 13, percentile: 1 },
          { rawScore: 14, percentile: 2.25 },
          { rawScore: 15, percentile: 6 },
          { rawScore: 16, percentile: 9 },
          { rawScore: 17, percentile: 13 },
          { rawScore: 18, percentile: 16 },
          { rawScore: 19, percentile: 27 },
          { rawScore: 20, percentile: 39 },
          { rawScore: 21, percentile: 50 },
          { rawScore: 22, percentile: 61 },
          { rawScore: 23, percentile: 84 },
          { rawScore: 24, percentile: 92 },
        ],
      },
      "66-78개월": {
        대근육운동: [
          { rawScore: 15, percentile: 1 },
          { rawScore: 16, percentile: 2.25 },
          { rawScore: 17, percentile: 6 },
          { rawScore: 18, percentile: 9 },
          { rawScore: 19, percentile: 13 },
          { rawScore: 20, percentile: 16 },
          { rawScore: 21, percentile: 33 },
          { rawScore: 22, percentile: 50 },
          { rawScore: 23, percentile: 67 },
          { rawScore: 24, percentile: 84 },
        ],
        소근육운동: [
          { rawScore: 15, percentile: 1 },
          { rawScore: 16, percentile: 2.25 },
          { rawScore: 17, percentile: 6 },
          { rawScore: 18, percentile: 9 },
          { rawScore: 19, percentile: 13 },
          { rawScore: 20, percentile: 16 },
          { rawScore: 21, percentile: 33 },
          { rawScore: 22, percentile: 50 },
          { rawScore: 23, percentile: 67 },
          { rawScore: 24, percentile: 84 },
        ],
        인지: [
          { rawScore: 9, percentile: 1 },
          { rawScore: 10, percentile: 2.25 },
          { rawScore: 11, percentile: 4 },
          { rawScore: 12, percentile: 6 },
          { rawScore: 13, percentile: 8 },
          { rawScore: 14, percentile: 10 },
          { rawScore: 15, percentile: 12 },
          { rawScore: 16, percentile: 14 },
          { rawScore: 17, percentile: 16 },
          { rawScore: 18, percentile: 26 },
          { rawScore: 19, percentile: 35 },
          { rawScore: 20, percentile: 45 },
          { rawScore: 21, percentile: 55 },
          { rawScore: 22, percentile: 65 },
          { rawScore: 23, percentile: 74 },
          { rawScore: 24, percentile: 84 },
        ],
        언어: [
          { rawScore: 11, percentile: 1 },
          { rawScore: 12, percentile: 2.25 },
          { rawScore: 13, percentile: 5 },
          { rawScore: 14, percentile: 8 },
          { rawScore: 15, percentile: 11 },
          { rawScore: 16, percentile: 13 },
          { rawScore: 17, percentile: 16 },
          { rawScore: 18, percentile: 26 },
          { rawScore: 19, percentile: 35 },
          { rawScore: 20, percentile: 45 },
          { rawScore: 21, percentile: 55 },
          { rawScore: 22, percentile: 65 },
          { rawScore: 23, percentile: 74 },
          { rawScore: 24, percentile: 84 },
        ],
        사회성: [
          { rawScore: 12, percentile: 1 },
          { rawScore: 13, percentile: 2.25 },
          { rawScore: 14, percentile: 6 },
          { rawScore: 15, percentile: 9 },
          { rawScore: 16, percentile: 13 },
          { rawScore: 17, percentile: 16 },
          { rawScore: 18, percentile: 26 },
          { rawScore: 19, percentile: 35 },
          { rawScore: 20, percentile: 45 },
          { rawScore: 21, percentile: 55 },
          { rawScore: 22, percentile: 65 },
          { rawScore: 23, percentile: 74 },
          { rawScore: 24, percentile: 84 },
        ],
        자조: [
          { rawScore: 12, percentile: 1 },
          { rawScore: 13, percentile: 2.25 },
          { rawScore: 14, percentile: 5 },
          { rawScore: 15, percentile: 8 },
          { rawScore: 16, percentile: 11 },
          { rawScore: 17, percentile: 13 },
          { rawScore: 18, percentile: 16 },
          { rawScore: 19, percentile: 27 },
          { rawScore: 20, percentile: 39 },
          { rawScore: 21, percentile: 50 },
          { rawScore: 22, percentile: 61 },
          { rawScore: 23, percentile: 73 },
          { rawScore: 24, percentile: 84 },
        ],
      },
  };