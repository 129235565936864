import React, { useEffect, useState } from 'react';
import '../styles/surveyPage.css';

const ChecklistPage = ({ questions, selectedOptions, categoryIndex, onSelectOption }) => {
    const [localSelectedOptions, setLocalSelectedOptions] = useState(Array(questions.length).fill(null));

    useEffect(() => {
        setLocalSelectedOptions(Array(questions.length).fill(null));
    }, [categoryIndex, questions.length]);

    const handleOptionClick = (questionIndex, optionIndex) => {
        setLocalSelectedOptions(prevOptions => {
            const newOptions = [...prevOptions];
            newOptions[questionIndex] = optionIndex;
            return newOptions;
        });
        onSelectOption(questionIndex, optionIndex);
    };

    return (
        <div className="survey-page">
            {questions.map((questionData, questionIndex) => (
                <div key={questionIndex} className={`survey-question ${localSelectedOptions[questionIndex] === null ? 'unanswered' : ''}`}>
                    <p>{questionIndex + 1}. {questionData.question}</p>
                    {questionData.image && <img src={questionData.image} alt="question illustration" />}
                    <div className="survey-options">
                        {questionData.options.map((option, optionIndex) => (
                            <button
                                key={optionIndex}
                                className={localSelectedOptions[questionIndex] === optionIndex ? 'selected' : ''}
                                onClick={() => handleOptionClick(questionIndex, optionIndex)}
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChecklistPage;
