import React, { useContext, useState, useEffect } from "react";
import { SurveyContext } from "../context/SurveyContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/stepBasicInfo.css";

// API_BASE_URL을 환경 변수로 설정하거나 직접 지정합니다.
const API_BASE_URL =
  process.env.REACT_APP_API_URL || "https://www.gwchild.co.kr/api";

const regions = [
  // 지역 목록
  { name: "춘천", area: "영서1" },
  { name: "홍천", area: "영서2" },
  { name: "인제", area: "영서1" },
  { name: "양구", area: "영서1" },
  { name: "화천", area: "영서1" },
  { name: "철원", area: "영서1" },
  { name: "원주", area: "영서2" },
  { name: "횡성", area: "영서2" },
  { name: "평창", area: "영서2" },
  { name: "정선", area: "영동2" },
  { name: "영월", area: "영동2" },
  { name: "강릉", area: "영동1" },
  { name: "속초", area: "영동1" },
  { name: "양양", area: "영동1" },
  { name: "고성", area: "영동1" },
  { name: "태백", area: "영동2" },
  { name: "삼척", area: "영동2" },
  { name: "동해", area: "영동1" },
];

const StepBasicInfo = () => {
  const { updateSurveyData } = useContext(SurveyContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    region: "",
    area: "",
    institutionType: "",
    institutionName: "",
    authCode: "",
    writerType: "",
    childName: "",
    gender: "",
    birthdate: "",
    ageInMonths: "",
    primaryCaregiver: "",
    motherEducation: "",
    fatherEducation: "",
    incomeLevel: "",
  });

  const [institutionNames, setInstitutionNames] = useState([]);
  const [error, setError] = useState("");
  const [authMessage, setAuthMessage] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [showMobilePopup, setShowMobilePopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      if (name === "region") {
        const selectedRegion = regions.find((region) => region.name === value);
        return { ...prevData, region: value, area: selectedRegion.area };
      }
      return { ...prevData, [name]: value };
    });
    // 입력이 변경될 때 에러 메시지와 인증 메시지를 초기화합니다.
    setError("");
    setAuthMessage("");
  };

  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    
    if (dateRegex.test(inputDate)) {
      const [year] = inputDate.split('-');
      const inputYear = parseInt(year, 10);
      
      if (inputYear >= 2018 && inputYear <= 2021) {
        const birthDate = new Date(inputDate);
        const today = new Date();
        const ageInMonths = Math.floor((today - birthDate) / (1000 * 60 * 60 * 24 * 30.44));
        
        setFormData((prevData) => ({
          ...prevData,
          birthdate: inputDate,
          ageInMonths,
        }));
      } else {
        showMessage("생년월일은 2018년부터 2021년 사이여야 합니다.", true);
      }
    } else {
      showMessage("올바른 날짜 형식을 입력해주세요 (YYYY-MM-DD).", true);
    }
  };

  const showMessage = (message, isError = true) => {
    if (window.innerWidth <= 768) { // 모바일 화면 크기 기준
      setAuthMessage(message);
      setShowMobilePopup(true);
      setTimeout(() => {
        setShowMobilePopup(false);
        setAuthMessage("");
      }, 3000);
    } else {
      if (isError) {
        setError(message);
      } else {
        setAuthMessage(message);
      }
    }
  };

  const handleVerification = async () => {
    const { region, institutionType, institutionName, authCode } = formData;

    if (!region || !institutionType || !institutionName || !authCode) {
      showMessage("지역, 기관 유형, 기관명, 인증 번호를 모두 입력해주세요.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/survey/verify-institution`,
        {
          region,
          institutionType,
          institutionName,
          authCode,
        }
      );

      if (response.data.verified) {
        showMessage("인증되었습니다.", false);
        setIsVerified(true);
        localStorage.setItem("institutionId", response.data.institutionId);
      } else {
        showMessage("인증 번호가 틀렸습니다.");
        setIsVerified(false);
      }
    } catch (error) {
      console.error("Error verifying institution:", error.response || error);
      showMessage("인증 과정에서 오류가 발생했습니다.");
      setIsVerified(false);
    }
  };

  const checkDuplicateChild = async (
    region,
    institutionName,
    childName,
    birthdate
  ) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/survey/check-duplicate`,
        {
          region,
          institutionName,
          childName,
          birthdate,
        }
      );
      return response.data.isDuplicate;
    } catch (error) {
      console.error("Error checking duplicate child:", error.response || error);
      return false; // 에러 발생 시 중복이 아니라고 판단
    }
  };

  const handleSubmit = async () => {
    const {
      region,
      institutionType,
      institutionName,
      writerType,
      childName,
      gender,
      birthdate,
      primaryCaregiver,
      motherEducation,
      fatherEducation,
      incomeLevel,
      ageInMonths,
    } = formData;

    if (
      !region ||
      !institutionType ||
      !institutionName ||
      !writerType ||
      !childName ||
      !gender ||
      !birthdate ||
      !primaryCaregiver ||
      !motherEducation ||
      !fatherEducation ||
      !incomeLevel
    ) {
      setError("모든 필드를 입력해 주세요.");
      return;
    }

    if (!isVerified) {
      setError("기관 인증이 필요합니다.");
      return;
    }

    if (ageInMonths <= 29 || ageInMonths >= 79) {
      setError("아동의 생활연령이 본 대상과 맞지 않습니다.");
      return;
    }

    // 중복 체크
    const isDuplicate = await checkDuplicateChild(
      region,
      institutionName,
      childName,
      birthdate
    );
    if (isDuplicate) {
      setError("이미 선별검사가 완료된 아동입니다.");
      return;
    }

    updateSurveyData("basicInfo", formData);
    console.log("Saving ageInMonths to localStorage:", ageInMonths);
    localStorage.setItem("ageInMonths", ageInMonths);
    navigate("/test?step=4");
  };

  useEffect(() => {
    const fetchInstitutionNames = async () => {
      if (formData.region && formData.institutionType) {
        try {
          console.log(
            "Fetching institutions for:",
            formData.region,
            formData.institutionType
          );
          const response = await axios.get(`${API_BASE_URL}/institutions`, {
            params: {
              region: formData.region,
              institutionType: formData.institutionType,
            },
          });
          console.log("API Response:", response.data);
          setInstitutionNames(response.data);
        } catch (error) {
          console.error(
            "Error fetching institution names:",
            error.response || error
          );
        }
      }
    };

    fetchInstitutionNames();
  }, [formData.region, formData.institutionType]);

  return (
    <div className="basic-info-container">
      <div className="form-grid">
        <div className="column-1">
          <h2>지역 및 기관 정보</h2>
          <div className="form-group">
            <label>지역</label>
            <select
              name="region"
              value={formData.region}
              onChange={handleChange}
            >
              <option value="">지역을 선택하세요</option>
              {regions
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((region) => (
                  <option key={region.name} value={region.name}>
                    {region.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group">
            <label>권역</label>
            <input type="text" name="area" value={formData.area} readOnly />
          </div>
          <div className="form-group">
            <label>기관유형</label>
            <select
              name="institutionType"
              value={formData.institutionType}
              onChange={handleChange}
            >
              <option value="">기관유형을 선택하세요</option>
              <option value="유치원">유치원</option>
              <option value="어린이집">어린이집</option>
            </select>
          </div>
          <div className="form-group">
            <label>기관명</label>
            <select
              name="institutionName"
              value={formData.institutionName}
              onChange={handleChange}
            >
              <option value="">기관명을 선택하세요</option>
              {institutionNames.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>인증 번호</label>
            <div className="auth-input">
              <input
                type="text"
                name="authCode"
                value={formData.authCode}
                onChange={handleChange}
              />
              <button onClick={handleVerification}>인증</button>
            </div>
          </div>
        </div>
        <div className="column-2">
          <div className="child-info">
            <h2>유아 정보</h2>
            <div className="form-group">
              <label>아동명</label>
              <input
                type="text"
                name="childName"
                value={formData.childName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>성별</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value="">성별을 선택하세요</option>
                <option value="남">남</option>
                <option value="여">여</option>
              </select>
            </div>
            <div className="form-group">
              <label>생년월일</label>
              <input
                type="date"
                name="birthdate"
                value={formData.birthdate}
                onChange={handleDateChange}
                min="2018-01-01"
                max="2021-12-31"
              />
            </div>
            <div className="form-group">
              <label>생활연령 (개월)</label>
              <input
                type="text"
                name="ageInMonths"
                value={formData.ageInMonths}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="column-3">
          <div className="writer-info">
            <h2>작성자 및 가정환경 정보</h2>
            <div className="form-group">
              <label>작성자 유형</label>
              <select
                name="writerType"
                value={formData.writerType}
                onChange={handleChange}
              >
                <option value="">작성자 유형을 선택하세요</option>
                <option value="보호자">보호자</option>
                <option value="교사">교사</option>
              </select>
            </div>
            <div className="form-group">
              <label>주양육자</label>
              <select
                name="primaryCaregiver"
                value={formData.primaryCaregiver}
                onChange={handleChange}
              >
                <option value="">주양육자를 선택하세요</option>
                <option value="엄마">엄마</option>
                <option value="아빠">아빠</option>
                <option value="할머니">할머니</option>
                <option value="할아버지">할아버지</option>
                <option value="기타">기타</option>
                <option value="모름">모름</option>
              </select>
            </div>
            <div className="form-group">
              <label>어머니 학력</label>
              <select
                name="motherEducation"
                value={formData.motherEducation}
                onChange={handleChange}
              >
                <option value="">어머니 학력을 선택하세요</option>
                <option value="대학원이상">대학원이상</option>
                <option value="대졸">대졸</option>
                <option value="대학중퇴">대학중퇴</option>
                <option value="고졸">고졸</option>
                <option value="중졸이하">중졸이하</option>
                <option value="모름">모름</option>
              </select>
            </div>
            <div className="form-group">
              <label>아버지 학력</label>
              <select
                name="fatherEducation"
                value={formData.fatherEducation}
                onChange={handleChange}
              >
                <option value="">아버지 학력을 선택하세요</option>
                <option value="대학원이상">대학원이상</option>
                <option value="대졸">대졸</option>
                <option value="대학중퇴">대학중퇴</option>
                <option value="고졸">고졸</option>
                <option value="중졸이하">중졸이하</option>
                <option value="모름">모름</option>
              </select>
            </div>
            <div className="form-group">
              <label>소득분위</label>
              <select
                name="incomeLevel"
                value={formData.incomeLevel}
                onChange={handleChange}
              >
                <option value="">소득분위를 선택하세요</option>
                <option value="100-200만원">100-200만원</option>
                <option value="201-300만원">201-300만원</option>
                <option value="301-400만원">301-400만원</option>
                <option value="401-500만원">401-500만원</option>
                <option value="501만원 이상">501만원 이상</option>
                <option value="모름">모름</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      
      {showMobilePopup && (
        <div className="mobile-popup">
          <p>{authMessage}</p>
        </div>
      )}
      
      {!showMobilePopup && authMessage && (
        <p className={isVerified ? "success-message" : "error-message"}>
          {authMessage}
        </p>
      )}
      {!showMobilePopup && error && <p className="error-message">{error}</p>}
      <button className="submit-button" onClick={handleSubmit}>
        다음
      </button>
    </div>
  );
};

export default StepBasicInfo;