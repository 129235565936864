import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SurveyContext } from '../context/SurveyContext';
import '../styles/stepConsent.css';

const StepConsent = () => {
    const { updateSurveyData, surveyData } = useContext(SurveyContext);
    const navigate = useNavigate();

    const handleConsent = () => {
        updateSurveyData('privacyConsent', true);
        console.log('Current Survey Data (Consent):', surveyData);
        navigate('/test?step=3');
    };

    const handleUnconsent = () => {
        navigate('/');
    };

    return (
        <div className="consent-container">
            <h2>개인정보 동의서</h2>
            <div className="consent-content">
                <p>1. 강원특별자치도교육청 위탁 사업으로 진행되는 유아 언어발달검사 및 치료지원사업과 관련하여 다음과 같은 내용으로 개인정보를 수집합니다.</p>
                <table className="consent-table">
                    <thead>
                        <tr>
                            <th>항목</th>
                            <th>내용</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="consent-table-item">수집·이용 목적</td>
                            <td className="consent-table-content">언어발달 선별검사 실시 및 결과 확인, 심화검사 대상자 확인 및 통보, 사업 결과 통계</td>
                        </tr>
                        <tr>
                            <td className="consent-table-item">수집 개인정보</td>
                            <td className="consent-table-content">지역, 기관유형, 기관명, 아동명, 성별, 생년월일, 주양육자, 어머니 학력, 아버지 학력, 소득분위</td>
                        </tr>
                        <tr>
                            <td className="consent-table-item">보유 및<br/>이용기간</td>
                            <td className="consent-table-content">관련법에 따른 서류 보존기한까지</td>
                        </tr>
                    </tbody>
                </table>
                <p>2. 수집된 모든 개인정보는 개인정보보호법 시행령 제17조에 의해 보호됩니다.</p>
                <p>3. 유아의 보호자는 본 개인정보 수집·이용에 대한 동의를 거부하실 수 있으며, 이의 경우 사업 참여에 제한이 있을 수 있습니다.</p>
            </div>
            <div className="consent-wording">
                <p><b>유아 언어발달검사 및 치료지원사업팀(한림대학교 산학협력단)에<br />위와 같이 개인정보를 수집·이용하는 것에 동의합니다.</b></p>
            </div>
            <div className="button-group">
                <button className="consent-button" onClick={handleConsent}>동의</button>
                <button className="unconsent-button" onClick={handleUnconsent}>동의하지 않음</button>
            </div>
        </div>
    );
};

export default StepConsent;