import React from 'react';
import FAQ from '../components/FAQ';
import '../styles/faqPage.css';

const FAQPage = () => {
    return (
        <div className="FAQPage">
            <main>
                <h1>자주 묻는 질문</h1>
                <FAQ />
            </main>
        </div>
    );
};

export default FAQPage;